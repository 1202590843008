import React, {  Component } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { getLocalStorageInfo, getGlobalCookie, setGlobalCookie } from '../utils';
import { isMobile } from 'react-device-detect';
import { trackActivity } from '../utils/mixpanel';
import  PageLoader  from '../shared_elements/components/PageLoader';
import restrictIcon from '../assets/images/restrict_icon.svg';
import { imgStoragePath } from '../constants'
export const lockImgPath = 'https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/permissions/';
export default function UserRoleAccess(HocComponent, extraProps=[]){
  return class extends Component{
    constructor(props){
      super(props)
      this.state = {
        videoDialog: false
      }
    }

    componentDidMount(){
      if(getGlobalCookie('MRCalcVideo') === undefined || getGlobalCookie('MRCalcVideo') === null){
        this.setState({videoDialog: false}) // should be true
      }
    }

    render(){
      if(getLocalStorageInfo()){
        let permission = getLocalStorageInfo().user.permission;
        let baseUrl = lockImgPath +extraProps[0]+ '_' +extraProps[1]+'.jpg';
        if(permission[extraProps[0]] && permission[extraProps[0]][extraProps[1]] && permission[extraProps[0]][extraProps[1]].indexOf(extraProps[2]) != -1){
          return(
            <React.Fragment>
              <HocComponent {...this.props} />
              <Dialog
                open={this.state.videoDialog}
                onClose={() => this.setState({videoDialog: false}, () => setGlobalCookie('BtMessageIntro', true, 730))}
                aria-labelledby = "scroll-dialog-title"
                fullWidth={true}
                maxWidth={'md'}
              >
                <DialogTitle>Introduction</DialogTitle>
                <DialogContent style={{padding:'20px'}}>
                  <span>
                      Greetings from the SPARTA Team. <br/>
                      To offer our support to you, we have enabled a LIVE CHAT option in SPARTA. Seek assistance from our operators by clicking on the icon <img src={imgStoragePath + 'chatIcon.svg'} style={{width: '25px'}}/> on corner of your SPARTA window. We would be happy to help. <br/><br/><br/>
                      Thank you.
                    </span>
                </DialogContent>
                <DialogActions>
                  <Button color="primary" variant="contained"  onClick={() => this.setState({videoDialog: false}, () => setGlobalCookie('BtMessageIntro', true, 730))}>Okay</Button>
                </DialogActions>
                </Dialog>
            </React.Fragment>
          )
        }else{
          trackActivity('MR Permission Denied')
          return(
            <div>
                {/* <span className="warning-info-text" style={{textAlign: 'center', marginBottom: '20px', width: '100%', fontSize: '16px'}}>You don't have access to MR Calculator. Please contact <a href="mailto:support@sparta.aero">support@sparta.aero</a> for access.</span>
                <video style={isMobile ? {width: '100%'} : {width: '100%', height: window.innerHeight - 180 + 'px'}} src="https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/videos/mr_calculator.mp4" controls/> */}
                <div className="page-not-found">
                    <Grid container spacing={0}>
                    <Grid xs={12}>
                      <img src={restrictIcon} alt="Icon"/>
                      <h2>You’re not permitted to see this.</h2>
                      <p>The page you’re trying to access has restricted access,<br/>
                        please contact <a style={{color:'#3F51B5', textDecoration:'auto'}} href="mailto:support@sparta.aero">support@sparta.aero</a> to access this application.
                       </p>
                      <Link className="start-over btn btn-default return-home" onClick={() => window.location.assign("https://sparta.aero")}>Return Home</Link>
                    </Grid>
                  </Grid>
                </div>
            </div>
          )
        }
      }else{
        return <PageLoader />
      }

    }
  }
}
