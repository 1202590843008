import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, MenuItem, Menu, ListItemText, Avatar } from '@material-ui/core';
import { getLocalStorageInfo, getGlobalCookie } from '../../utils';
import config from '../../config';
const UserDropDownMenu = ({logout}) => {
  const user = JSON.parse(getGlobalCookie('userName'))
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let baseUrl = config.domain.subDomian;
  return(
    <div className="user-menu-dropdown">
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{padding:'0px 8px'}}
      >
        <Avatar alt={ getLocalStorageInfo() && getLocalStorageInfo().user ? getLocalStorageInfo().user.name:'--'} style={{marginRight: '10px', width: '34px', height: '34px'}} src={getLocalStorageInfo() && getLocalStorageInfo().user  ? getLocalStorageInfo().user.profile_pic:''} />
        <div className="" style={{textAlign: 'left'}}>
          <h3>{user && user.name ? user.name:'--'}</h3>
          <span>{user && user.designation ? user.designation:''}</span>
        </div>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={{marginTop:'40px'}}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => {handleClose(); window.open(baseUrl+'user-profile', '_blank').focus();}}>Profile</MenuItem>
        <MenuItem onClick={() => {handleClose(); window.open(baseUrl+'assets-listing?addUser=true', '_blank').focus();}}>Invite User</MenuItem>
        <MenuItem onClick={() => {handleClose();logout();}}>Logout</MenuItem>
      </Menu>
    </div>
  )
}
export default UserDropDownMenu;
