import React from 'react'
import { withRouter } from 'react-router'
import {Grid} from '@material-ui/core'
import { Link } from 'react-router-dom';
const PageNotFound  = (props) => {
  return(
    <Grid container >
      <Grid item xs="12" style={{minHeight: '90vh', position: 'relative'}}>
        <div className="page-not-found">
          <h1>404</h1>
          <h4>Page Not Found :(</h4>
          <Link className="start-over btn btn-default" onClick={() =>  props.history.push('/logs')}>Start Over Here!</Link>
        </div>
      </Grid>
    </Grid>
  )
}
export default withRouter(PageNotFound);
