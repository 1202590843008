import React from 'react';
import { Drawer, Button, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
const EditUserRole = ({editRole, roleList, onFieldChange, onChangeUserRole, toggleModalFn}) => {
  return(
    <Drawer anchor={'right'} open={editRole.modal} onClose={toggleModalFn}>
      { editRole.modal ?
        <div className="edit-user-role" style={{width:'320px'}}>
          <h2>Change Role</h2>
          <div className="user-info">
            <h4>{`${editRole.user.first_name} ${editRole.user.last_name}`}</h4>
            <p>{editRole.user.email}</p>
            <span>{editRole.user.sparta_group}</span>
          </div>
          <div className="user-role">
            <Autocomplete
              options = {roleList.map(role=>{return{...role,name:role.name  == 'MR Calculator View' ? 'CommVerge View' : role.name =='MR Calculator Manager' ? 'CommVerge Manager' : role.name}})}
              clearOnBlur={false}
              value={editRole.role}
              getOptionLabel={option => option.name}
              filterSelectedOptions={true}
              onChange={(e, value) => onFieldChange(value)}
              renderInput={params => <TextField {...params} label="Select Role" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
            />
            <ul className="list-inline">
              <li className="list-inline-item">
                <Button color="primary" variant="contained" size="small" onClick={onChangeUserRole}>Apply</Button>
              </li>
              <li className="list-inline-item">
                <Button color="primary" variant="outlined" size="small" onClick={toggleModalFn}>Cancel</Button>
              </li>
            </ul>
          </div>
        </div>:null
      }
    </Drawer>
  )
}
export default EditUserRole;
