import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Grid, Button, TextField, Link } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { User, EditUserRole } from '../components';
import CloseIcon from '@material-ui/icons/Close';
import { DeletePopUp, PageLoader, EmptyCollection } from '../../../shared_elements';
import { removeEmptyKey } from '../../../utils';
import { getAppsUsersApi, revokeUserAccessApi, onChangeUserRoleApi, getRolesApi, exportUserListApi } from '../apiServices';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import config from '../../../config';
import { trackActivity } from '../../../utils/mixpanel';
class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editRole: { modal: false, user: null },
      removeUser: {
        modal: false,
        user: null
      },
      search: '',
      status: '',
      skeletonLoader: false,
      pageLoader: false,
      users: {
        list: [],
        active_users_count: '',
        inactive_users_count: '',
        invitation_sent_users_count: '',
        pagination: null
      },
      roleList: []
    }
    this.getAppsUsersApi = getAppsUsersApi.bind(this);
    this.exportUserListApi = exportUserListApi.bind(this);
    this.revokeUserAccessApi = revokeUserAccessApi.bind(this);
    this.getRolesApi = getRolesApi.bind(this);
    this.onChangeUserRoleApi = onChangeUserRoleApi.bind(this);
  }
  componentDidMount() {
    this.getAppsUsersApi(this.props, { page: 1, per_page: 20 }, 'pageLoader');
    this.getRolesApi(this.props, {});
    trackActivity('Page Visited', {
      application_sub_module: 'Settings',
      page_title: 'Users'
    })
  }
  moveNextPage = () => {
    const { users, search, status } = this.state;
    let nextPage = parseInt(users.pagination.page) + 1;
    this.getAppsUsersApi(this.props, removeEmptyKey({ per_page: users.pagination.per_page, page: nextPage, search: search, filter_type: status && status.value ? status.value : '' }), 'pageLoader');
  }
  onRemoveUser = (user) => {

  }
  handleChange = (data) => {
    const { users, search } = this.state
    this.setState(prevState => ({
      ...prevState,
      status: data
    }))
    this.getAppsUsersApi(this.props, removeEmptyKey({ per_page: users.pagination.per_page, search: search, filter_type: data ? data.value : '' }, 'pageLoader'))
  }
  render() {
    const { pageLoader, roleList, users, removeUser, editRole, search, status } = this.state;
    var userStatusDropdown = [
      { label: `All (0)`, value: '' },
      { label: `Active (0)`, value: 'active' },
      { label: `Deactivated (0)`, value: 'inactive' },
      { label: `Invitation sent (0)`, value: 'invitation-sent' }
    ]
    if (users && users.list) {
      userStatusDropdown = [
        { label: `All (${users.active_users_count + users.inactive_users_count + users.invitation_sent_users_count})`, value: '' },
        { label: `Active (${users.active_users_count})`, value: 'active' },
        { label: `Deactivated (${users.inactive_users_count})`, value: 'inactive' },
        { label: `Invitation sent (${users.invitation_sent_users_count ? users.invitation_sent_users_count : 0})`, value: 'invitation-sent' }
      ]
    }
    return (
      <section className="users-section">
        <div className="users-header">
          <Grid alignItems='center' container spacing={1}>
            <Grid item xs={12} sm={12} md={3} >
              <Grid container spacing={1}>
                <Grid item md={6}>
                  <Button color="primary" onClick={() => this.exportUserListApi(this.props, { extension: "xls", search: search, filter_type: status && status.value ? status.value : '' })} style={{ marginLeft: '5px' }} ><SaveAltIcon /> Export</Button>
                </Grid>
              </Grid>
              <p style={{ fontSize: '14px', marginBottom: '8px' }}>Contains the list of users</p>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              <ul className="list-inline">
                <li className="list-inline-item" style={{ width: '200px', position: 'relative', bottom: '15px' }}>
                  <Autocomplete
                    options={userStatusDropdown}
                    getOptionLabel={option => option.label}
                    value={status}
                    onChange={(e, value) => this.handleChange(value)}
                    renderInput={params => <TextField {...params} placeholder="User Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} />}
                  />
                </li>
                <li className="list-inline-item search-fields">
                  <TextField
                    fullWidth
                    margin="none"
                    value={search}
                    onChange={(e) => this.setState({ search: e.target.value })}
                    placeholder="Search user by Name"
                    InputLabelProps={{ shrink: true }}
                  />
                  <span className="user-search-close"><CloseIcon onClick={() => { this.setState({ search: '' }); this.getAppsUsersApi(this.props, { page: 1, per_page: 20, filter_type: status && status.value ? status.value : '' }, 'pageLoader'); }} fontSize="small" /></span>
                </li>
                <li className="list-inline-item">
                  <Button type="submit" onClick={() => this.getAppsUsersApi(this.props, removeEmptyKey({ page: 1, per_page: 20, search: search, filter_type: status && status.value ? status.value : '' }), 'pageLoader')} color="primary" variant="outlined" size="small">Search</Button>
                </li>
                <li className="list-inline-item">
                  <Button color="primary" variant="contained" size="small"><Link href={`${config.domain.subDomian}users/manage?addUser=true`} style={{ textDecoration: 'none', color: 'white' }} target="_blank">Add User</Link></Button>
                </li>
              </ul>
            </Grid>
          </Grid>
        </div>
        <InfiniteScroll
          dataLength={users.list ? users.list.length : 0}
          next={() => this.moveNextPage()}
          hasMore={users.list && users.pagination ? users.list.length < users.pagination.total : false}
          loader={<h4 style={{ clear: 'both', textAlign: 'center', padding: '10px' }}>Loading...</h4>}
          endMessage={users.list && users.list.length !== 0 ? <p style={{ clear: 'both', textAlign: 'center', padding: '10px' }}>Yay! You have seen it all</p> : null}
        >
          <Grid container spacing={1} style={{ margin: '7px' }}>
            {users && users.list && users.list.length ? users.list.sort((a, b) => (a.first_name > b.first_name) ? 1 : ((b.first_name > a.first_name) ? -1 : 0)).map((item, index) =>
              <User
                item={item}
                onRemoveUser={() => this.setState({ removeUser: { modal: true, user: item } })}
                onChangeRole={() => this.setState({ editRole: { modal: true, role: null, user: item } })}
              />
            ) : null}
          </Grid>
        </InfiniteScroll>
        {!pageLoader && users.list.length === 0 ? <EmptyCollection title="No users found" /> : null}
        {removeUser.modal ?
          <DeletePopUp
            modal={removeUser.modal}
            toggleModalFn={() => this.setState({ removeUser: { modal: false, user: null } })}
            title={'Remove User'}
            content={<h4>{`Are you sure you want to remove?`}</h4>}
            triggerEventFn={() => this.revokeUserAccessApi(this.props, removeUser.user)}
            confirmText={'Remove'}
          /> : null
        }
        <EditUserRole
          roleList={roleList}
          editRole={editRole}
          onFieldChange={(value) =>
            this.setState(prevState => ({
              ...prevState,
              editRole: {
                ...prevState.editRole,
                role: value
              }
            }))
          }
          onChangeUserRole={() => this.onChangeUserRoleApi(this.props, editRole)}
          toggleModalFn={() => this.setState({ editRole: { modal: false, user: null } })}
        />
        {pageLoader ? <PageLoader /> : null}
      </section>
    )
  }
}
export default withSnackbar(Users);
