import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import { ExportManu, PageLoader } from '../../../shared_elements';
import { regexConstants } from '../../../constants/regEx';
import { deRateVariableApi, calculateDeRateApi, exportDeRateApi } from '../apiServices';
class LOICalModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      derate:{
        incRatio:'',
        ratios:[],
        derates:[]
      },
      error:{},
      deRateMatrix:[],
      deRateList:[],
      modal:false,
      deRateMatrixModal:false
    };
    this.deRateVariableApi = deRateVariableApi.bind(this);
    this.calculateDeRateApi = calculateDeRateApi.bind(this);
    this.exportDeRateApi = exportDeRateApi.bind(this);
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error:{
        ...prevState.error,
        [key]:message
      }
    }))
  }
  updateCondition = (condition) => {
    if(this.state.derate.derates.indexOf(condition.id) === -1){
      if(this.state.derate.derates.length < 4){
        this.setState(prevState => ({
          ...prevState,
          derate: {
            ...prevState.derate,
            derates: [...prevState.derate.derates, condition.id]
          },
          error:{
            ...prevState.error,
            derates:''
          }
        }));
      }
    }else{
      this.setState(prevState => ({
        ...prevState,
        derate: {
          ...prevState.derate,
          derates: prevState.derate.derates.filter(id => id !== condition.id)
        }
      }));
    }
  }
  updateIncRatio = (e) => {
    const { engineBasicInfo } = this.props;
    let rationRange = [engineBasicInfo.ratio];
    const value = e.target.value;
    let basicRatio = engineBasicInfo.ratio;
    if(regexConstants.numberWithDot.test(value) && value > 0 && value < engineBasicInfo.max_ratio){
      for(var i =0; i < 4; i++){
        if(basicRatio > engineBasicInfo.min_ratio){
          basicRatio -= value;
          if(basicRatio > 0 && basicRatio > engineBasicInfo.min_ratio){
            rationRange.push(parseFloat(basicRatio.toFixed(1)));
          }
        }
      }
      basicRatio = parseFloat(engineBasicInfo.ratio);
      for(var j =0; j<4; j++){
        if(basicRatio < engineBasicInfo.max_ratio){
          basicRatio += parseFloat(value);
          if(basicRatio > 0 && basicRatio < engineBasicInfo.max_ratio){
            rationRange.push(parseFloat(basicRatio.toFixed(1)));
          }
        }
      }
    }
    this.setState(prevState => ({
      ...prevState,
      derate:{
        ...prevState.derate,
        incRatio:value,
        ratios: rationRange.sort(function(a, b){return a - b}),
      },
    }));
  }
  render(){
    const { modal, deRateMatrixModal, deRateMatrix, derate, deRateList, pageLoader, error } = this.state;
    const { engineBasicInfo, log_id, buttonText } = this.props;
    return(
      <Fragment>
        { !modal ? <Button style={{float:'right'}} color="primary" onClick={() => {this.setState({modal:true});this.deRateVariableApi(this.props)}} variant="contained">{buttonText ? buttonText:'LOI MR Table'}</Button>:
          <Dialog
            open={modal}
            onClose={() => this.setState({modal:false, deRateList:[], derate:{incRatio:'',ratios:[],derates:[]}})}
            aria-labelledby="scroll-dialog-title"
            maxWidth={'md'}
            fullWidth={true}
          >
            <DialogTitle id="scroll-dialog-title">
            LOI MR TABLE
            </DialogTitle>
            <DialogContent dividers={true}>
              <div className="engine-derate-modal">
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={6}>
                    <TextField
                      id='utilization_ratio'
                      fullWidth
                      label="Base Utilization Ratio"
                      margin="normal"
                      value={engineBasicInfo.ratio}
                      InputLabelProps={{shrink: true}}
                      disabled={true}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <TextField
                      required
                      id='incRatio'
                      fullWidth
                      label="Enter Increment Ratio"
                      error={error.incRatio ? true : false}
                      helperText={error.incRatio ? error.incRatio : '' }
                      onFocus={() => this.updateErrorField('incRatio', '')}
                      margin="normal"
                      value={derate.incRatio}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ?  this.updateIncRatio(e) : e.preventDefault()}
                      variant="outlined"
                    />
                  </Grid>
                  { derate.ratios.length ?
                    <Grid item xs={12} sm={12} md={12}>
                      <div className="loi-row">
                        <label className="label">Projected Ratio Range</label>
                        <table>
                          <tr>
                            {derate.ratios.map((ratio,index) => <td className={ratio === engineBasicInfo.ratio ? 'base-ratio':''} key={index}>{ratio}</td>)}
                          </tr>
                        </table>
                      </div>
                    </Grid>:null
                  }
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <div className="loi-row">
                      <label style={error.derates ? {color:'#f44335'} : {}}>Select upto 4 De-rate value <sup>*</sup></label>
                      <table>
                        <tr>
                          {deRateList.map((deRate, index) => <td onClick={() => this.updateCondition(deRate)} className={ derate.derates.indexOf(deRate.id) !== -1 ? 'selected':'' } key={index}>{deRate.condition}</td>)}
                        </tr>
                      </table>
                      {error && error.derates ?
                        <span style={{position:'relative', color:'#f44335', top:'5px'}}>{error.derates}<br/></span> : null
                      }
                      <span className="notice-info" style={{position:'relative', color:'#000', top:'10px'}}>Note: Maximum 4 De-Rate values can be selected for calculation</span>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => this.setState({modal:false, deRateList:[],error:{}, derate:{incRatio:'',ratios:[],derates:[]}})}>Cancel</Button>
              <Button color="primary" variant="contained" onClick={() => this.calculateDeRateApi(this.props, {...derate, engine_no:engineBasicInfo.engineNo})}>Calculate MR Rates</Button>
            </DialogActions>
          </Dialog>
        }
        { deRateMatrixModal ?
          <Dialog
            open={deRateMatrixModal}
            onClose={() => this.setState({deRateMatrixModal:false, deRateMatrix:[]})}
            aria-labelledby="scroll-dialog-title"
            maxWidth={'md'}

          >
            <DialogTitle id="scroll-dialog-title">
              LOI MR TABLE
              <ExportManu title="Export" files={[{title:'Excel', extension: 'xls', key:''}]} exportReportFn={(file) => this.exportDeRateApi(this.props, {data:deRateMatrix, download:'xlsx'})} />
            </DialogTitle>
            <DialogContent dividers={true}>
              <div className="derate-matrix-blk-modal">
                <table>
                  <thead>
                    <tr>
                      <th>Ratio / Derate</th>
                      {deRateMatrix[0].ratios.map((ratio,index) => <th className="text-center">{ratio.ratio}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    { deRateMatrix.map((deRate,index) =>
                      <tr>
                        <td>{deRate.percentage}</td>
                        { deRate.ratios.map( (ratio, childIndex) =>
                          <td className="text-center">${ratio.mr}</td>
                        )}
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => this.setState({deRateMatrixModal:false, deRateMatrix:[]})}>Okay</Button>
            </DialogActions>
          </Dialog>:null
        }
        { pageLoader ? <PageLoader/>:null }
      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(LOICalModal));
