import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
const EngineEventBarChart = ({label, events, index}) => {
    const cat = Object.keys(events).map((event) => events[event][index].eventDate)
    const eventCost = Object.keys(events).map((event) => events[event][index].eventCost)
    const fundCollected = Object.keys(events).map((event) => events[event][index].fundCollected)
    const balance = Object.keys(events).map((event) => events[event][index].difference)
    console.log('cat', cat)
    const options = {
        chart: {
            type: 'column'
        },
        title: {text: ''},
        xAxis: {
            categories: cat
        },
        yAxis: {
            title: {text: ''}            
        },
        
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: [
            {
                name: 'Event Cost',
                data: eventCost,
                color:'#0066FF',
                tooltip:{
                    valuePrefix:'US$'
                }
            }, 
            {
                name: 'Fund Collected',
                data: fundCollected,
                color:'#F9AC2A',
                tooltip:{
                    valuePrefix:'US$'
                }
            }, 
            {
                name: 'Balance',
                data: balance,
                color:'#23E477',
                negativeColor: 'red',
                tooltip:{
                    valuePrefix:'US$'
                }
            }
        ],
        credits: {
            enabled: false
        },
    }
    return(
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )
}
export default EngineEventBarChart;