import React, { Fragment } from 'react';
import { Table, TableCell, TableRow, Grid, TextField, Button, IconButton, Tooltip, InputAdornment, Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import  { isMobile} from 'react-device-detect';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';
import { getLocalStorageInfo } from '../../../utils';
import { regexConstants } from '../../../constants/regEx';
import EditIcon from '@material-ui/icons/Edit';
import GaugeChart from './GaugeChart';
const APUCard = ({apuEdit, toggleEditFn, apuUpdateUsageFn, cancelUsageUpdateFn, mrLogDetail, onFieldChange, genericError, updateGenericErrorField, openInfoPopupFlag,closeInfoPopup,apuInfoPopup, apuUpdateUsageApi, isLocked}) => {
  const fundDateValidation = () => {
    const tsn =  mrLogDetail.apu.tsn
    const utilizationFH = mrLogDetail.apu.hourlyUtlization
    const minDate  = (tsn/utilizationFH) * 30.44
    return moment().subtract(parseInt(minDate), 'd')
  }
  return(
    <div className='apu-assembly-card'>
      <div>
        <Grid alignItems='center' container spacing={1}>
          <Grid item md={9}>
            <Table border={1} borderColor='#d7d7d7'>
              <TableRow>
                <TableCell width={ mrLogDetail.apu.dateOfManufacture ? '25%':'33.33%'}>
                  <p>APU Type</p>
                  <h4>{mrLogDetail.apu.apu_type && mrLogDetail.apu.apu_type.name ? mrLogDetail.apu.apu_type.name :'--' }</h4>
                </TableCell>
                <TableCell width={ mrLogDetail.apu.dateOfManufacture ? '25%':'33.33%'}>
                  <p>Time Since New {mrLogDetail.apu.as_of_date ? mrLogDetail.apu.to_show ? `(as of ${moment(mrLogDetail.apu.as_of_date).format(displayDateFormatShort)})` : '':mrLogDetail.generalInfo.created_at ? `(as of ${moment(mrLogDetail.generalInfo.created_at).format(displayDateFormatShort)})` : ''}</p>
                  <h4>{mrLogDetail.apu.to_show ? mrLogDetail.apu.tsn : '--'}</h4>
                </TableCell>
                { mrLogDetail.apu.dateOfManufacture ?
                  <TableCell width={ mrLogDetail.apu.dateOfManufacture ? '25%':'33.33%'}>
                    <p>Date of Manufacture</p>
                    <h4>{mrLogDetail.apu.dateOfManufacture ? moment(mrLogDetail.apu.dateOfManufacture).format(displayDateFormatShort):'--'}</h4>
                  </TableCell>:null
                }
                <TableCell width={ mrLogDetail.apu.dateOfManufacture ? '25%':'33.33%'}>
                  <p>Assumed Utilization APU Hour</p>
                  <h4>{mrLogDetail.apu.hourlyUtlization ? `${mrLogDetail.apu.hourlyUtlization} / Month` :'--'}</h4>
                </TableCell>
              </TableRow>
            </Table>
          </Grid>
          <Grid item md={3}>
            <ul className="list-inline assembly-cta-list">
              { !apuEdit ?
                getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow']  && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1 && !isLocked  ?
                <li className="list-inline-item">
                  <Tooltip title="Edit APU" arrow>
                    <IconButton color="primary" component="span" size="small">
                      <EditIcon onClick={toggleEditFn} color="primary" />
                    </IconButton>
                  </Tooltip>
                </li> : null
                :!isLocked ?
                  <Fragment>
                    <li className="list-inline-item">
                      <Button size="small" color="primary" variant="contained" onClick={apuUpdateUsageFn}>Save</Button>
                    </li>
                    <li className="list-inline-item">
                      <Button size="small" color="primary" variant="outlined" onClick={cancelUsageUpdateFn}>Cancel</Button>
                    </li>
                  </Fragment> : null
              }
            </ul>
          </Grid>
        </Grid>
        <div className='apu-assembly-event'>
          <div className='event-name'>
            <h4>APU</h4>
          </div>
          <div className='event-details'>
            <Grid container spacing={1}>
              <Grid item md={8}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6} md={4}>
                    <TextField
                      disabled={!apuEdit}
                      id="apu_currentFundValue"
                      label="Fund Balance"
                      fullWidth
                      margin="normal"
                      value={mrLogDetail.apu.currentFundValue}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'currentFundValue', e.target.value, 'apu'): e.preventDefault()}}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">US$</InputAdornment>
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        disabled={!apuEdit}
                        required
                        margin="normal"
                        id="apu_fundDate"
                        label="Fund Balance as of"
                        format={fieldDateFormat}
                        fullWidth
                        error={genericError && genericError.fundDate ? true : false}
                        helperText={genericError && genericError.fundDate ? genericError.fundDate :''}
                        InputLabelProps={{shrink: true}}
                        value={mrLogDetail.apu.fundDate ? mrLogDetail.apu.fundDate:null}
                        maxDate={moment()}
                        minDate={mrLogDetail.apu.dateOfManufacture ? moment(mrLogDetail.apu.dateOfManufacture): fundDateValidation()}
                        onChange={(data, value) => {onFieldChange(value, 'fundDate', data ? moment(data).format(backendDateFormat):data, 'apu'); updateGenericErrorField('fundDate', '') }}
                        inputVariant="outlined"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <TextField
                      disabled={!apuEdit}
                      required
                      id="apu_actualMR"
                      label="MR Rate"
                      fullWidth
                      margin="normal"
                      error={genericError && genericError.actualMR ? true : false}
                      helperText={ genericError && genericError.actualMR ? genericError.actualMR :''}
                      onFocus={() => updateGenericErrorField('actualMR', '') }
                      value={mrLogDetail.apu.actualMR}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'actualMR', e.target.value, 'apu'): e.preventDefault()}}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">US$</InputAdornment>,
                        endAdornment: <InputAdornment position="end">/ {mrLogDetail.apu.interval_type === 'hourly' ? 'APU H':'Month'}</InputAdornment>
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <TextField
                      disabled={!apuEdit}
                      id="apu_escalation"
                      label="MR Rate Esc.(Annual)"
                      fullWidth
                      margin="normal"
                      value={mrLogDetail.apu.escalation}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'escalation', e.target.value, 'apu'):e.preventDefault()}}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <TextField
                      disabled={!apuEdit}
                      id="apu_cost_escalation"
                      label="Event Cost Esc.(Annual)"
                      fullWidth
                      margin="normal"
                      value={mrLogDetail.apu.cost_escalation}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'cost_escalation', e.target.value, 'apu'):e.preventDefault()}}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        disabled={!apuEdit}
                        margin="normal"
                        id="apu_nextShopvisitDate"
                        label="Next Qualified Event Date"
                        format={fieldDateFormat}
                        fullWidth
                        disablePast={true}
                        InputLabelProps={{shrink: true}}
                        value={mrLogDetail.apu.nextShopvisitDate ? mrLogDetail.apu.nextShopvisitDate:null}
                        onChange={(data, value) => {onFieldChange(value, 'nextShopvisitDate', data ? moment(data).format(backendDateFormat):data);}}
                        inputVariant="outlined"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        disabled={!apuEdit}
                        margin="normal"
                        id="apu_lastShopvisitDate"
                        label="Last Qualified Event Date"
                        format={fieldDateFormat}
                        error={genericError && genericError.lastShopvisitDate ? true :false}
                        helperText={genericError && genericError.lastShopvisitDate ? genericError.lastShopvisitDate : ''}
                        fullWidth
                        maxDate={moment()}
                        InputLabelProps={{shrink: true}}
                        value={mrLogDetail.apu.lastShopvisitDate ? mrLogDetail.apu.lastShopvisitDate:null}
                        onChange={(data, value) => {onFieldChange(value, 'lastShopvisitDate', data ? moment(data).format(backendDateFormat):data); updateGenericErrorField('lastShopvisitDate', '')}}
                        inputVariant="outlined"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <TextField
                      disabled={!apuEdit}
                      required={(mrLogDetail.apu.lastShopvisitDate || mrLogDetail.apu.tsn_at_lsv || mrLogDetail.apu.csn_at_lsv) ? true:false}
                      id="tsn_at_lsv"
                      label="TSN @ Last SV"
                      fullWidth
                      error={genericError && genericError.tsn_at_lsv ? true : false}
                      helperText={genericError && genericError.tsn_at_lsv ? genericError.tsn_at_lsv : ''}
                      onFocus={() =>  updateGenericErrorField('tsn_at_lsv', '')}
                      margin="normal"
                      value={mrLogDetail.apu.tsn_at_lsv}
                      InputLabelProps={{shrink: true}}
                      inputProps={{ maxLength: 6 }}
                      onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tsn_at_lsv', e.target.value, 'apu'):e.preventDefault()}}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <TextField
                      disabled={true}
                      id="event_cost"
                      label="Next Qualified Event Cost"
                      fullWidth
                      margin="normal"
                      value={mrLogDetail.apu.event_cost}
                      InputLabelProps={{shrink: true}}
                      variant="outlined"
                      InputProps={{
                        startAdornment: <InputAdornment position="end">US$</InputAdornment>
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4}>
                <GaugeChart 
                  unit={' / APUH'}
                  min={mrLogDetail.apu.minMR}
                  max={mrLogDetail.apu.maxMR}
                  actual={mrLogDetail.apu.targetMR}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      {apuInfoPopup ?
        <Dialog
          open={apuInfoPopup}
          onClose={closeInfoPopup}
          aria-labelledby="scroll-dialog-title"
        >
          <DialogContent dividers={true}>
            <div className="" style={isMobile ? {width:'auto'}:{minWidth:'400px'}}>
              <p className="" style={{alignItems:'center',marginTop:'70px' }} align="center" >
              Last Shop Visit Date is in between <strong>Fund Balance as of the date</strong> and <strong>Next Qualified Event Date</strong>.
              {/*Fund Calculation will start from <strong>Last Shop Visit Date ({moment(mrLogDetail.apu.lastShopvisitDate).format(displayDateFormatShort)})</strong>.*/}
              Confirm to proceed.
              </p>
              <div className="" align="center" style={{marginTop:'20px'}}  >
                <Button variant="contained" onClick={apuUpdateUsageApi} color="primary" style={{marginRight:'5px'}}>confirm</Button>
                <Button variant="outlined" onClick={closeInfoPopup} color={'primary'}>Cancel</Button>
              </div>
            </div>
          </DialogContent>
        </Dialog> : null
      }
    </div>
  )
}
export default APUCard;
