const env = process.env.REACT_APP_ENV
export const appConfig = {
  api: {
    networkInterface: ({
      development: 'https://dev.beta.sparta.aero:8222/',
      staging: 'https://qa.beta.sparta.aero:8223/',
      production: 'https://api.sparta.aero/',
    })[env],
    sftpNetworkInterface: ({
      development: 'https://dev.beta.sparta.aero:8222/',
      staging: 'https://qa.beta.sparta.aero:8223/',
      production: 'https://sftp.sparta.aero/',
    })[env],
    // add more here
    s3ImageUrl: ({
      development: 'https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/',
      staging: 'https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/',
      production: 'https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/',
    })[env],
  },
  mixpanel: {
    key: ({
      production: 'b8fd0e714d2f134f22d8fb0d9e646158',
      staging: 'c4e6387ced1f81d89691c3c39701abfc',
      development: 'c4e6387ced1f81d89691c3c39701abfc',
    })[env]
  },
  domain: {
    subDomian: ({
      development: 'https://qa.console.sparta.aero/',
      staging: 'https://qa.console.sparta.aero/',
      production: 'https://sparta.aero/',
    })[env],
    lease: ({
      development: 'https://qa.lease.sparta.aero/',
      staging: 'https://qa.lease.sparta.aero/',
      production: 'https://lease.sparta.aero/',
    })[env],
    maintenance: ({
      development: 'https://qa.maintenance.sparta.aero/',
      staging: 'https://qa.maintenance.sparta.aero/',
      production: 'https://maintenance.sparta.aero/',
    })[env],
    projects: ({
      development: 'https://qa.projects.sparta.aero/',
      staging: 'https://qa.projects.sparta.aero/',
      production: 'https://projects.sparta.aero/',
    })[env],
    valuation: ({
      development: 'https://dev.fin-s.sparta.aero/',
      staging: 'https://qa.fin-s.sparta.aero/',
      production: 'https://fin-s.sparta.aero/',
    })[env],
    mrCalculator: ({
      development: 'https://qa.mr-calculator.sparta.aero/',
      staging: 'https://qa.mr-calculator.sparta.aero/',
      production: 'https://mr-calculator.sparta.aero/',
    })[env],
    records: ({
      development: 'https://qa.records.sparta.aero/',
      staging: 'https://qa.records.sparta.aero/',
      production: 'https://records.sparta.aero/',
    })[env],
  }
}

export default appConfig
