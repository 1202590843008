import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { Button, TableRow, TableCell, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid } from '@material-ui/core';
import { TableListComp, PageLoader } from '../../../shared_elements';
import { customCycleRemainApi, engineUsageCalApi } from '../apiServices';
import { regexConstants } from '../../../constants/regEx';
class CustomzieLLPModal extends Component{
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      modal:false,
      engineNo:'',
      customCycleRemain:null,
      originalList:null,
      wrongEntry:'',
      llpLimits:[]

    };
    this.customCycleRemainApi = customCycleRemainApi.bind(this);
  }
  onFieldChange = (e, keyParam, value, index) => {
    const { customCycleRemain } = this.state;
    let editCycles = false
    if(value != customCycleRemain[index].csn){
      editCycles = true;
    }
    if(value != customCycleRemain[index].max_limit){
      editCycles = true
    }
    if(value != customCycleRemain[index].remaining){
      editCycles = true
    }else {
      editCycles = false
    }
    switch (keyParam){
      case 'csn':
        this.setState(prevState => ({
          ...prevState,
          customCycleRemain:{
            ...prevState.customCycleRemain,
            [index]:{
              ...prevState.customCycleRemain[index],
              [keyParam]: value ? parseInt(value) : 0,
              remaining: prevState.customCycleRemain[index].max_limit - value,
              editCycles:editCycles
            }
          },
        }))
        break;
      case 'max_limit':
      if(!value){
        this.setState(prevState => ({
          ...prevState,
          customCycleRemain:{
            ...prevState.customCycleRemain,
            [index]:{
              ...prevState.customCycleRemain[index],
              [keyParam]:value ? parseInt(value) : 0,
              remaining:0,
              csn:0,
              editCycles:editCycles
            }
          },
        }))
      }else {
        this.setState(prevState => ({
          ...prevState,
          customCycleRemain:{
            ...prevState.customCycleRemain,
            [index]:{
              ...prevState.customCycleRemain[index],
              [keyParam]:value ? parseInt(value) : 0,
              remaining:value - prevState.customCycleRemain[index].csn,
              editCycles:editCycles
            }
          },
        }))
      }

        break;
      case 'remaining':
        this.setState(prevState => ({
          ...prevState,
          customCycleRemain:{
            ...prevState.customCycleRemain,
            [index]:{
              ...prevState.customCycleRemain[index],
              [keyParam]:value ? parseInt(value) : 0,
              csn:prevState.customCycleRemain[index].max_limit - value,
              editCycles:editCycles
            }
          },
        }))
        break;
      default:
    }
    // this.setState(prevState => ({
    //   ...prevState,
    //   customCycleRemain:{
    //     ...prevState.customCycleRemain,
    //     [index]: {
    //       ...prevState.customCycleRemain[index],
    //       [keyParam]:value,
    //       editCycles:editCycles
    //     }
    //   }
    // }));
  }
  compareCustomCycleRemainingFn = () => {
    const { customCycleRemain, originalList } = this.state;
    const { mrLogDetail, engineIndex, engine,lsvDate } = this.props;
    let customizeLLP = [];
    let customizeWrong = false;
    Object.keys(customCycleRemain).map((label) => {
      if(customCycleRemain[label].remaining <= customCycleRemain[label].max_limit){
        if(mrLogDetail.engines[engineIndex].llpLimits.filter(item => item.id === label).length){
          customizeLLP.push(customCycleRemain[label]);
        }else{
          if(customCycleRemain[label].remaining != originalList[label].remaining || customCycleRemain[label].csn != mrLogDetail.engines[engineIndex].csn || customCycleRemain[label].max_limit != originalList[label].max_limit ){
            customizeLLP.push(customCycleRemain[label]);
          }
        }
      }else{
        customizeWrong = true;
      }
    });
    if(customizeWrong){
      this.setState({
        wrongEntry:'Cycle Remaining Cannot be Greater the Max Limit'
      });
      this.props.enqueueSnackbar('Cycle Remaining Cannot be Greater the Max Limit',{variant:'error',anchorOrigin:{vertical: 'top',horizontal: 'right'}})
    }else{
      this.props.updateCustomLLPCyclesFn(engineIndex, customizeLLP.filter(item =>  item.editCycles), engine, customCycleRemain)
      this.setState({
        modal:false,
        customCycleRemain:null,
        originalList:null
      })
    }

  }
  updateLLpLimits = (index) => {
    this.setState(prevState => ({
      ...prevState,
      llpLimits:[...prevState.llpLimits, index]
    }))
  }
  disableFields = (flag) => {
    let disableFlag = false
    if(flag){
      return disableFlag = false
    }
    if(!this.props.lsvDate){
      if(flag) {
        return disableFlag = false
      }else {
        return disableFlag = true
      }
    }
  }
  render(){
    const { modal, customCycleRemain, wrongEntry, pageLoader,llpLimits } = this.state;
    const { mrLogDetail, engineIndex, enginesEdit, toggleEditFn, viewLlpFn, engine, lsvDate, isLocked} = this.props;
    let LlpIndex = [...new Set(llpLimits)]
    return(
      <Fragment>
        { !modal ?
          <span
            style={{color:'#3f51b5', textDecoration:'underline', cursor:'pointer', fontSize:'14px', paddingLeft:'4px'}}
            onClick={() => this.customCycleRemainApi(this.props, mrLogDetail.generalInfo.engineType.id, engineIndex, mrLogDetail)}
          >
            { enginesEdit ? 'Edit LLPs':'View LLPs' }
          </span>:
          <Dialog
            open={modal}
            onClose={() => this.setState({modal:false})}
            aria-labelledby="scroll-dialog-title"
            fullScreen
          >
            <DialogTitle id="scroll-dialog-title">
              <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                  {enginesEdit ? 'Update':''} Life Limited Parts
                  <p style={{fontSize:'13px', color:'#8b8d91'}}>These values will be used to set the starting point of an LLPs Life during operation</p>
                </Grid>
                <Grid item xs={12} md={3}>
                {  !isLocked ?
                    !enginesEdit ?
                    <Button color="primary" variant="contained" style={{float:'right'}} onClick={toggleEditFn} >Edit LLP</Button>
                    :<Button color="primary" variant="outlined" style={{float:'right'}} onClick={viewLlpFn} >View LLP</Button>
                    : null
                  }
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent dividers={true}>
              <div>
                <TableListComp
                  size={'small'}
                  heads={[{label:'Description', sortOption:false},{label:'CSN', sortOption:false},  {label:'Cycle Limit', sortOption:false}, {label:'Cycles Remaining', sortOption:false}, {label:'Life (%) Remaining', sortOption:false}]}
                  data={Object.keys(customCycleRemain).map((label, index) =>{
                      let disabledFlag = true
                      if(lsvDate){
                        disabledFlag = false
                      }else {
                        if(customCycleRemain[label].on_wing_replaceable){
                          disabledFlag = false
                        }
                      }
                    return (
                      <TableRow hover tabIndex={-1}>
                        <TableCell style={{width:'120px'}}>
                          {customCycleRemain[label].description} <br/>
                        { enginesEdit && disabledFlag ? <span style={{fontSize:'10px',padding:'2px 4px', color:'#000', borderRadius:'3px', background:'#fff1b6' }}>Please update Last Shop Visit to edit</span> : null}
                        </TableCell>
                        <TableCell style={{width:'120px'}}>
                          { enginesEdit ?
                            <TextField
                              id={`csn_${index}`}
                              fullWidth
                              margin="normal"
                              value={customCycleRemain[label].csn}
                              InputLabelProps={{shrink: true}}
                              disabled={disabledFlag}
                              inputProps={{ maxLength: 6 }}
                              onChange={(e) => {regexConstants.onlyNumeric.test(e.target.value) && e.target.value <= customCycleRemain[label].max_limit ? this.onFieldChange(e, 'csn', e.target.value, label):e.preventDefault()}}
                              onBlur={() => this.updateLLpLimits(label) }
                            />:
                            customCycleRemain[label].csn
                          }
                        </TableCell>
                        <TableCell style={{width:'120px'}}>
                          { enginesEdit ?
                            <TextField
                              id={`max_limit_${index}`}
                              fullWidth
                              margin="normal"
                              inputProps={{ maxLength: 10 }}
                              value={customCycleRemain[label].max_limit}
                              InputLabelProps={{shrink: true}}
                              disabled={disabledFlag}
                              onChange={(e) => {regexConstants.onlyNumeric.test(e.target.value) ? this.onFieldChange(e, 'max_limit', e.target.value, label):e.preventDefault()}}
                              onBlur={() => this.updateLLpLimits(label)}
                            />:
                            customCycleRemain[label].max_limit
                          }
                        </TableCell>
                        <TableCell style={{width:'120px'}}>
                          { enginesEdit ?
                            <TextField
                              id={`remaining_${index}`}
                              fullWidth
                              margin="normal"
                              disabled={disabledFlag}
                              inputProps={{ maxLength: 10 }}
                              value={customCycleRemain[label].remaining}
                              InputLabelProps={{shrink: true}}
                              onBlur={() => this.updateLLpLimits(label) }
                              onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) && e.target.value <= customCycleRemain[label].max_limit ?  this.onFieldChange(e, 'remaining', e.target.value, label):e.preventDefault()}}
                            />:
                            customCycleRemain[label].remaining
                          }
                        </TableCell>
                        <TableCell style={{width:'120px'}}>{ customCycleRemain[label].remaining && customCycleRemain[label].max_limit ?  (customCycleRemain[label].remaining/customCycleRemain[label].max_limit*100).toFixed(2) : 0}</TableCell>
                      </TableRow>
                    )
                  }

                  )}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => this.setState({modal:false})}>Cancel</Button>
              { enginesEdit ?
                <Button color="primary" variant="contained" onClick={() => this.compareCustomCycleRemainingFn()}>Save</Button>:null
              }
            </DialogActions>
          </Dialog>
        }
        { pageLoader ? <PageLoader />:null }
      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(CustomzieLLPModal));
