import React, { Component, Fragment } from 'react';
import { withSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import moment from 'moment';
import { Table, TableHead, TableBody, TableRow, TableCell, Tabs, Tab, Button, Grid, Paper, Breadcrumbs, Typography, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import { PageLoader, CountryStateSelector } from '../../../shared_elements';
import { ListAnalyticSW, EOLProjection, AssemblyEventInfo, EngineEventInfo, EngineEventList, EngineBasicInfo, LOWGraph, MrCashflow, EventsBarChart, EngineEventBarChart, Comments } from '../components';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { checkApiStatus, getLocalStorageInfo } from '../../../utils';
import { trackActivity } from '../../../utils/mixpanel'
import { generateSimulateResultApi, mrFinanceGraphApi, mrFinanceLifeOfWingsApi, updateRegionMapsApi, graphWidgetSum } from '../apiServices';
class MrGraphicalReps extends Component {
  constructor(props){
    super(props);
    this.state = {
      eolMRIndex:0,
      lowFlag:true,
      listMode:true,
      refNoModal:false,
      assemblyIndex:'airframe',
      apuErrorFlag:false,
      lgErrorFlag:false,
      airframeErrorFlag:false,
      engineErrorFlag:false,
      address:'',
      pageLoader:false,
      tabIndex:'summary',
      regionModal: false,
      engineIndex:0,
      prsvLlpIndex:0,
      airframeIndex:0,
      simulationType:'1', // 1:Read Mode, 2: Edit Mode, 3: Simulated Mode
      financeRptInfo:{},
      totalFundWidget:null,
      lifeOnWings:{},
      copyfinanceRptInfo:{},
      simulateFinanceRptInfo:{},
      financeError:{},
      fundAsOf:moment(),
      regionError:{},
      stateFlagCheck:false,
      regionDetails: {},
    }
    this.mrFinanceGraphApi = mrFinanceGraphApi.bind(this);
    this.mrFinanceLifeOfWingsApi = mrFinanceLifeOfWingsApi.bind(this);
    this.generateSimulateResultApi = generateSimulateResultApi.bind(this);
    this.updateRegionMapsApi = updateRegionMapsApi.bind(this);
  }
  componentDidMount(){
    this.mrFinanceGraphApi(this.props);
    trackActivity('Page Visited', {page_title: `Analyze MR Cashflow`, log_id: this.props.match.params.id})
  }
  handleChange = (event, newValue) => {
    this.setState({tabIndex:newValue})
  }
  handleAFChange = (event, newValue) => {
    this.setState({airframeIndex:newValue})
  }
  handleEngineChange = (event, newValue) => {
    this.setState({engineIndex:newValue, prsvLlpIndex:0})
  }
  handlePrsvLlpChange = (event, newValue) => {
    this.setState({prsvLlpIndex:newValue})
  }
  updateErrorField = (assemblyType, key, message, label) => {
    if(assemblyType == "airframe" && this.state.financeError.airframe){
      this.setState(prevState => ({
        ...prevState,
        financeError:{
          ...prevState.financeError,
          [assemblyType]:{
            ...prevState.financeError[assemblyType],
            [label]:{
              ...prevState.financeError[assemblyType][label],
              [key]:message
            }
          }
        }
      }))
    }else {
      this.setState(prevState =>({
        ...prevState,
        financeError:{
          ...prevState.financeError,
          [assemblyType]:{
            ...prevState.financeError[assemblyType],
            [key]:message
          }
        }
      }))
    }
  }
  updateRegionErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      regionError:{
        ...prevState.regionError,
        [key]:message
      }
    }))
  }
  updateEventsErrorField = (assemblyType, key, message, label, eventLabel) => {
    if((assemblyType == "apu" && this.state.financeError.apu) || (assemblyType == "landing_gear" && this.state.financeError.landing_gear)){
      this.setState(prevState => ({
        ...prevState,
        financeError:{
          ...prevState.financeError,
          [assemblyType]:{
            ...prevState.financeError[assemblyType],
            ['events']:{
              ...prevState.financeError[assemblyType]['events'],
              [eventLabel]:{
                ...prevState.financeError[assemblyType]['events'][eventLabel],
                [key]:message
              }
            }
          }
        }
      }))
    }
    if(assemblyType == "airframe" && this.state.financeError.airframe){
      this.setState(prevState => ({
        ...prevState,
        financeError:{
          ...prevState.financeError,
          [assemblyType]:{
            ...prevState.financeError[assemblyType],
            [label]:{
              ...prevState.financeError[assemblyType][label],
              ['events']:{
                ...prevState.financeError[assemblyType][label]['events'],
                [eventLabel]:{
                  ...prevState.financeError[assemblyType][label]['events'][eventLabel],
                  [key]:message
                }
              }
            }
          }
        }
      }))
    }
  }
  updateErrorEngineField = (key, message, primaryLabel = "", secondaryLabel = "", tertiaryLabel = "") => {
    if(this.state.financeError.engines && secondaryLabel){
      this.setState(prevState => ({
        ...prevState,
        financeError:{
          ...prevState.financeError,
          engines:{
            ...prevState.financeError.engines,
            [primaryLabel]:{
              ...prevState.financeError.engines[primaryLabel],
              ['shop_visits']:{
                ...prevState.financeError.engines[primaryLabel]['shop_visits'],
                [secondaryLabel]:{
                  ...prevState.financeError.engines[primaryLabel]['shop_visits'][secondaryLabel],
                  [tertiaryLabel]:{
                    ...prevState.financeError.engines[primaryLabel]['shop_visits'][secondaryLabel][tertiaryLabel],
                    [key]:message
                  }
                }
              }
            }
          }
        }
      }))
    }if(['hourly_utilization', 'cycle_utilization', 'utilization_ratio', 'regionOfOperation'].includes(key)){
      this.setState(prevState => ({
        ...prevState,
        financeError:{
          ...prevState.financeError,
          general_info:{
            ...prevState.financeError.general_info,
            [key]:''
          }
        }
      }))
    }else {
      this.setState(prevState => ({
        ...prevState,
        financeError:{
          ...prevState.financeError,
          engines:{
            ...prevState.financeError.engines,
            [primaryLabel]:{
              ...prevState.financeError.engines[primaryLabel],
              [key]:message
            }
          }
        }
      }))
    }
  }

  onFieldChange = (event, keyParam, value, tableType, svType, eventId, prsvllpId) => {
    const { financeRptInfo } = this.state;
    if(tableType === 'airframe'){
      if(eventId){
        this.setState(prevState => ({
          ...prevState,
          financeRptInfo:{
            ...prevState.financeRptInfo,
            [tableType]:{
              ...prevState.financeRptInfo[tableType],
              [svType]:{
                ...prevState.financeRptInfo[tableType][svType],
                events:{
                  ...prevState.financeRptInfo[tableType][svType].events,
                  [eventId]:{
                    ...prevState.financeRptInfo[tableType][svType].events[eventId],
                    [keyParam]:value
                  }
                }
              }
            }
          }
        }))
      }else{
        this.setState(prevState => ({
          ...prevState,
          financeRptInfo: {
            ...prevState.financeRptInfo,
            [tableType]:{
              ...prevState.financeRptInfo[tableType],
              [svType]:{
                ...prevState.financeRptInfo[tableType][svType],
                [keyParam]:value
              }
            }
          }
        }))
      }
    }else if (tableType === 'engines') {
      if(svType === 'general_info'){
        if(keyParam === 'cycle_utilization' || keyParam === 'hourly_utilization'){
          let ratio = 0;
          if(keyParam === 'cycle_utilization'){
            ratio = value && value !==0 ? financeRptInfo[tableType].general_info.hourly_utilization/value:0
          }else{
            ratio = value ? value/financeRptInfo[tableType].general_info.cycle_utilization:0
          }
          this.setState(prevState => ({
            ...prevState,
            financeRptInfo: {
              ...prevState.financeRptInfo,
              [tableType]:{
                ...prevState.financeRptInfo[tableType],
                general_info:{
                  ...prevState.financeRptInfo[tableType].general_info,
                  utilization_ratio:ratio && ratio !== Infinity? ratio.toFixed(1):0
                }
              }
            }
          }));
        }
        this.setState(prevState => ({
          ...prevState,
          financeRptInfo: {
            ...prevState.financeRptInfo,
            [tableType]:{
              ...prevState.financeRptInfo[tableType],
              general_info:{
                ...prevState.financeRptInfo[tableType].general_info,
                [keyParam]:value
              }
            }
          }
        }));
      }
      if(eventId === 'engine'){
        this.setState(prevState => ({
          ...prevState,
          financeRptInfo:{
            ...prevState.financeRptInfo,
            [tableType]:{
              ...prevState.financeRptInfo[tableType],
              engine_data: {
                ...prevState.financeRptInfo[tableType].engine_data,
                [svType]: {
                  ...prevState.financeRptInfo[tableType].engine_data[svType],
                  [keyParam]:value
                }
              }
            }
          }
        }))
      }
      if(eventId && prsvllpId){
        this.setState(prevState => ({
          ...prevState,
          financeRptInfo: {
            ...prevState.financeRptInfo,
            [tableType]: {
              ...prevState.financeRptInfo[tableType],
              engine_data:{
                ...prevState.financeRptInfo[tableType].engine_data,
                [svType]: {
                  ...prevState.financeRptInfo[tableType].engine_data[svType],
                  shop_visits:{
                    ...prevState.financeRptInfo[tableType].engine_data[svType].shop_visits,
                    [eventId]: {
                      ...prevState.financeRptInfo[tableType].engine_data[svType].shop_visits[eventId],
                      [prsvllpId]:{
                        ...prevState.financeRptInfo[tableType].engine_data[svType].shop_visits[eventId][prsvllpId],
                        [keyParam]:value
                      }
                    }
                  }
                }
              }
            }
          }
        }))
      }
    }else{
      if(eventId){
        this.setState(prevState => ({
          ...prevState,
          financeRptInfo: {
            ...prevState.financeRptInfo,
            [tableType]:{
              ...prevState.financeRptInfo[tableType],
              events:{
                ...prevState.financeRptInfo[tableType].events,
                [eventId]:{
                  ...prevState.financeRptInfo[tableType].events[eventId],
                  [keyParam]:value
                }
              }
            }
          }
        }))
      }else{
        this.setState(prevState => ({
          ...prevState,
          financeRptInfo: {
            ...prevState.financeRptInfo,
            [tableType]: {
              ...prevState.financeRptInfo[tableType],
              [keyParam]:value
            }
          }
        }));
      }
    }
  }
  handleSelect = address => {
    this.setState({address, showMarker: false}, () => {
      geocodeByAddress(address)
        .then(results => {this.getAddressDetails(results[0])})
    });
  };

  updateLocationWithoutGoogle = (key, value, stateFlag) => {
    let regionObj = this.state.regionDetails ? Object.assign({}, this.state.regionDetails) : {}
    this.setState({stateFlagCheck:stateFlag })
    switch (key) {
      case 'country':
        regionObj = {
          ...regionObj,
          country_name: value ? value.name : '',
          country_iso_2: value ? value.alpha_code_two : ''
        }
        break;
      case 'state':
        regionObj = {
          ...regionObj,
          state_name: value ? value.name : '',
          state_code: value ? value.state_code : ''
        }
        break;
      case 'city':
        regionObj = {
          ...regionObj,
          city_name: value ? value.city_name : '',
          city_code: value ? value.city_name : ''
        }
        break;
    }
    trackActivity('Region Selected', {source: 'Custom Dropdowns', page_title: 'Analyze MR Cashflow', log_id: this.props.match.params.id})
    this.setState(prevState => ({
      ...prevState,
      regionDetails:{...regionObj, google_api: false, address: stateFlag ? `${regionObj.city_name ? regionObj.city_name + ', ' : ''}${regionObj.state_name ? regionObj.state_name + ', ' : ''}${regionObj.country_name ? regionObj.country_name : ''}` :`${regionObj.city_name ? regionObj.city_name + ', ' : ''}${regionObj.country_name ? regionObj.country_name : ''}`},
    }), () => {
      if(key === 'save'){
        if(!stateFlag && regionObj.country_name){
          this.setState({regionModal: false})
          this.updateRegionMapsApi(this.props, {location:{...regionObj, state_name:'',...this.state.location}, engine_type:160})
          .then(response => {
            if(checkApiStatus(response)){
              this.setState(prevState => ({
                ...prevState,
                financeRptInfo: {
                  ...prevState.financeRptInfo,
                  engines:{
                    ...prevState.financeRptInfo.engines,
                    general_info:{
                      ...prevState.financeRptInfo.engines.general_info,
                      region: response.data.data.current_region
                    }
                  },
                  location:regionObj
                }
              }))
            }else{
              this.props.enqueueSnackbar(response.data.message,{variant:'error',anchorOrigin:{vertical: 'top',horizontal: 'right'}})
            }
          })
          this.setState(prevState => ({
            ...prevState,
            regionDetails:regionObj,
            error:{
              ...prevState.error,
              regionOfOperation:''
            }
          }));
        }
        if(stateFlag && regionObj.country_name && regionObj.state_name){
          this.updateRegionMapsApi(this.props, {location:{...regionObj,...this.state.location}, engine_type:160})
          .then(response => {
            if(checkApiStatus(response)){
              this.setState(prevState => ({
                ...prevState,
                financeRptInfo: {
                  ...prevState.financeRptInfo,
                  engines:{
                    ...prevState.financeRptInfo.engines,
                    general_info:{
                      ...prevState.financeRptInfo.engines.general_info,
                      region: response.data.data.current_region
                    }
                  },
                  location:regionObj
                }
              }))
              this.setState({regionModal: false})
            }else{
              this.props.enqueueSnackbar(response.data.message,{variant:'error',anchorOrigin:{vertical: 'top',horizontal: 'right'}})
              if(!regionObj.country_name) {
                this.setState(prevState => ({
                  ...prevState,
                  regionError:{
                    ...prevState.regionError,
                    country:'Please Select Country'
                  }
                }))
              }
              if(!regionObj.state_name){
                this.setState(prevState => ({
                  ...prevState,
                  regionError:{
                    ...prevState.regionError,
                    state:'Please select State'
                  }
                }))
              }
            }
          })
          this.setState(prevState => ({
            ...prevState,
            regionDetails:regionObj,
            error:{
              ...prevState.error,
              regionOfOperation:''
            }
          }));
        }else{
          // this.props.enqueueSnackbar('Not able to identify the location, please select again', {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
          if(!regionObj.country_name) {
            this.setState(prevState => ({
              ...prevState,
              regionError:{
                ...prevState.regionError,
                country:'Please Select Country'
              }
            }))
          }
          if(!regionObj.state_name){
            this.setState(prevState => ({
              ...prevState,
              regionError:{
                ...prevState.regionError,
                state:'Please select State'
              }
            }))
          }
        }
      }
    })
  }

  getAddressDetails = (address) => {
    const { financeRptInfo } = this.state
    if(address){
      const { financeRptInfo } = this.state;
      this.setState({address:address.formatted_address})
      let regionObj = {};
      address.address_components.map(location => {
        let types = location.types[0];
        switch (types) {
          case 'country':
            regionObj = {
              ...regionObj,
              country_name: location.long_name ? location.long_name : '',
              country_iso_2: location.short_name ? location.short_name : ''
            }
            return
          case "administrative_area_level_1":
            regionObj = {
              ...regionObj,
              state_name: location.long_name ? location.long_name : '',
              state_code: location.short_name ? location.short_name : ''
            }
            return
          case "administrative_area_level_2":
              regionObj = {
                ...regionObj,
                city_name: location.long_name ? location.long_name : '',
                city_code: location.short_name ? location.short_name : ''
              }
              return
          default:
            return
        }
      });
      regionObj = {
        ...regionObj,
        address: `${regionObj.city_name ? regionObj.city_name: ''}${regionObj.city_name ? ', ' : ''}${regionObj.state_name ? regionObj.state_name : ''}${regionObj.state_name ? ', ' :''}${regionObj.country_name}`
      }
      if(regionObj.country_iso_2){
        this.updateRegionMapsApi(this.props, {location:{...regionObj,...this.state.location}, engine_type:financeRptInfo.engines.general_info.engine_type.id})
        .then(response => {
          if(checkApiStatus(response)){
            this.setState(prevState => ({
              ...prevState,
              financeRptInfo: {
                ...prevState.financeRptInfo,
                engines:{
                  ...prevState.financeRptInfo.engines,
                  general_info:{
                    ...prevState.financeRptInfo.engines.general_info,
                    region: response.data.data.current_region
                  }
                },
                location:regionObj
              }
            }))
          }else{
            this.props.enqueueSnackbar(response.data.message,{variant:'error',anchorOrigin:{vertical: 'top',horizontal: 'right'}})
          }
        })
        this.setState(prevState => ({
          ...prevState,
          regionDetails:{...regionObj, google_api: true},
          error:{
            ...prevState.error,
            regionOfOperation:''
          }
        }));
      }else{
        this.props.enqueueSnackbar('Not able to identify the location, please select again', {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    }else{
      this.props.enqueueSnackbar('Not able to identify the location, please select again', {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  }

  render(){
    const { eolMRIndex, listMode, lowFlag, refNoModal, address, assemblyIndex, pageLoader, tabIndex, engineIndex, prsvLlpIndex, airframeIndex, fundAsOf, financeRptInfo, copyfinanceRptInfo, totalFundWidget, lifeOnWings, simulateFinanceRptInfo, simulationType, financeError, apuErrorFlag,airframeErrorFlag, lgErrorFlag, engineErrorFlag, regionError, stateFlagCheck } = this.state;
    return(
      <Fragment>
        <section className="mr-calculator-section">
          <Grid container className="mr-title">
            <Grid item xs={12} sm={7} md={5}>
              <Breadcrumbs aria-label="breadcrumb" style={{fontSize:'16px'}}>
                <Link color="inherit" to="/logs" >
                  <Typography className="flex-centered" component="h1">Logs</Typography>
                </Link>
                {getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('C') != -1 ?
                  <Link color="inherit" to="/initiate" >
                    <Typography className="flex-centered" component="h1" >
                      Generate Finance Report
                    </Typography>
                  </Link> : null
                }
                <Link to={`/intermediate/${this.props.match.params.id}`}>
                  <Typography className="flex-centered" component="h1">{this.props.match.params.id}</Typography>
                </Link>
                <p className="flex-centered" color="primary">
                  MR Cashflow
                  
                </p>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12} sm={7} md={7} className="mr-log-info">
              <ul className="list-inline flex-centered" style={{float:'right'}}>
                <li className='list-inline-item' style={{ cursor: 'pointer', marginRight:'6px' }}>
                  <Comments />
                </li>
              </ul> 
            </Grid>
          </Grid>
          <div>
            <Paper className="mr-projection-tabs" style={{marginBottom:'10px'}}>
              <Tabs
                value={eolMRIndex}
                onChange={(event, newValue) => this.setState({eolMRIndex:newValue})}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                style={{marginBottom:'0'}}
              >
                <Tab label={`MR Projection`} />
                <Tab label={`EOL Projection`} />
              </Tabs>
            </Paper>
          </div>
          {eolMRIndex === 0 ?
            <div>
              <Grid container className="mr-title">
                <Grid item xs={12} className="mr-log-info">
                  <ul className="list-inline flex-centered" style={{float:'right'}}>
                    { listMode === true ?
                      <Fragment>
                        { simulationType === '1' ?
                          <li className="list-inline-item">
                            <Button size="small" onClick={() => {this.setState({simulationType:'2'});trackActivity(`Log Simulation`, {page_title: 'Analyze MR Cashflow', log_id:this.props.match.params.id})}} color="primary" variant="contained">Simulate Scenarios</Button>
                          </li>:null
                        }
                        { simulationType === '2' ?
                          <Fragment>
                            <li className="list-inline-item" onClick={() => {this.setState({simulationType:'1',simulateFinanceRptInfo:null, financeError:{}});this.mrFinanceGraphApi(this.props, assemblyIndex) }}>
                              <Button color='primary' variant='outlined' size='small'>Reset to Default</Button>
                            </li>
                            <li className="list-inline-item">
                              <Button size="small" onClick={() => this.generateSimulateResultApi(this.props, financeRptInfo)} color="primary" variant="contained">Apply</Button>
                            </li>
                          </Fragment>:null
                        }
                        { simulationType === '3' ?
                          <Fragment>
                            <li className="list-inline-item" onClick={() => {this.setState({simulationType:'1',simulateFinanceRptInfo:null, financeError:{}}); this.mrFinanceGraphApi(this.props, assemblyIndex)}}>
                              <Button color='primary' variant='outlined' size='small'>Reset to Default</Button>
                            </li>
                            <li className="list-inline-item">
                              <Button size="small" onClick={() => this.setState({simulationType:'2', simulateFinanceRptInfo:null, address:copyfinanceRptInfo.location && copyfinanceRptInfo.location.address ? copyfinanceRptInfo.location.address: copyfinanceRptInfo.engines ? copyfinanceRptInfo.engines.general_info.region.name:''})} color="primary" variant="contained">Simulate Another Scenarios</Button>
                            </li>
                          </Fragment>:null
                        }
                      </Fragment>:null
                    }
                    
                    <li className="list-inline-item">
                      <MrCashflow
                        copyfinanceRptInfo={copyfinanceRptInfo}
                        simulateFinanceRptInfo={simulateFinanceRptInfo}
                        simulationType={simulationType}
                      />
                    </li>
                    <li className='list-inline-item'>
                      <ListAnalyticSW 
                        listMode={listMode}
                        toggleViewMode={(flag) => this.setState({listMode:flag})}
                      />
                    </li>
                  </ul> 
                </Grid>
              </Grid>
              <Paper className='commverge-intermediate-card'>
                <div className='commverge-assembly-menu'>
                  {financeRptInfo.airframe ?
                    <div onClick={(assemblyType) => this.setState({assemblyIndex:'airframe'})} className={`menu-option ${assemblyIndex === 'airframe' ? 'active':''}`}>
                      <h6>Airframe</h6>
                    </div>:null
                  }
                  { financeRptInfo.engines ?
                    <div onClick={(assemblyType) => this.setState({assemblyIndex:'engines'})} className={`menu-option ${assemblyIndex === 'engines' ? 'active':''}`}>
                      <h6>Engines</h6>
                    </div>:null
                  }
                  { financeRptInfo.apu ?
                    <div onClick={(assemblyType) => this.setState({assemblyIndex:'apu'})} className={`menu-option ${assemblyIndex === 'apu' ? 'active':''}`}>
                      <h6>APU</h6>
                    </div>:null
                  }
                  { financeRptInfo.landing_gear ?
                    <div onClick={(assemblyType) => this.setState({assemblyIndex:'landing_gear'})} className={`menu-option ${assemblyIndex === 'landing_gear' ? 'active':''}`}>
                      <h6>Landing Gears</h6>
                    </div>:null
                  }
                </div>
                <div className='commverge-assembly-projection' style={{minHeight:`${window.innerHeight-100}px`, overflow:'auto'}}>
                  { assemblyIndex === 'airframe' && financeRptInfo.airframe && Object.keys(financeRptInfo.airframe).length ?
                    <div className='assembly-projection-events'>
                      { listMode ?
                        <Fragment>
                          { Object.keys(financeRptInfo.airframe).map((label, index) =>
                            <AssemblyEventInfo
                              assemblyType='airframe'
                              log_id={this.props.match.params.id}
                              assemblyInfo={financeRptInfo.airframe[label]}
                              assemblyInfoSimulated={simulationType === '3' ? simulateFinanceRptInfo.airframe[label]:{}}
                              simulationType={simulationType}
                              updateErrorField={this.updateErrorField}
                              updateEventsErrorField={this.updateEventsErrorField}
                              financeError={financeError && financeError.airframe && financeError.airframe[label]}
                              onFieldChange={this.onFieldChange}
                              checkYr={label}
                            />
                          )}   
                        </Fragment>:
                        <div>
                          { Object.keys(financeRptInfo.airframe).map((label, index) =>
                            <Paper style={{borderRadius:0}} className="events-bar-chart">
                              <div className='events-bar-chart-title'>
                                <h4>{financeRptInfo.airframe[label].name.replace('s', '')} Check</h4>
                              </div>
                              <Grid container spacing={2}>
                                <Grid item md={simulationType === '3' ?'6':'12'}>
                                  { simulationType === '3' ? <h5 style={{padding:'10px'}}>Without Simulation</h5>:null}
                                  <EventsBarChart  label={financeRptInfo.airframe[label].name} events={financeRptInfo.airframe[label].events} />
                                </Grid>
                                {simulationType === '3' ?
                                  <Grid item md={6}>
                                    <h5 style={{padding:'10px'}}>With Simulation</h5>
                                    <EventsBarChart label={simulateFinanceRptInfo.airframe[label].name} events={simulateFinanceRptInfo.airframe[label].events} />
                                  </Grid>:null
                                }
                              </Grid>
                            </Paper>
                          )}
                        </div>               
                      }
                    </div>:null
                  }
                  { assemblyIndex === 'engines' && financeRptInfo.engines && Object.keys(financeRptInfo.engines).length ?
                    <div className='engine-events-projection'>
                      { listMode ?
                        <div>
                          <div>
                            <EngineBasicInfo
                              address={address}
                              financeRptInfo={financeRptInfo}
                              simulateFinanceRptInfo={simulationType === '3' ? simulateFinanceRptInfo:{}}
                              simulationType={simulationType}
                              financeError={financeError && financeError['general_info'] ? financeError['general_info'] : {}}
                              onFieldChange={this.onFieldChange}
                              updateErrorEngineField={this.updateErrorEngineField}
                              handleSelect={this.handleSelect}
                              onAddressChange={(address) => this.setState({address:address})}
                              regionDetails={this.state.regionDetails}
                              updateLocationWithoutGoogle={(key, value) => this.updateLocationWithoutGoogle(key, value)}
                              setRegionModal={(flag) => this.setState({regionModal: flag})}
                            />
                          </div>
                          <Tabs
                            value={engineIndex}
                            onChange={this.handleEngineChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="normal"
                            aria-label="full width tabs example"
                          >
                            { Object.keys(financeRptInfo.engines.engine_data).map((label, index) =>
                              <Tab label={`Engine #${index+1}`} />
                            )}
                          </Tabs>
                          { Object.keys(financeRptInfo.engines.engine_data).map((label, index) =>
                            engineIndex === index ?
                            <div>
                              <div className="engine-events-card">
                                <div className="life-on-wings">
                                  <div className='low-title'>
                                    <h4 className='flex-centered'>
                                      Life on Wing
                                      { lowFlag ? <KeyboardArrowDownIcon onClick={() => this.setState({lowFlag:false})} style={{marginLeft:'auto', cursor:'pointer'}} />:<KeyboardArrowRightIcon onClick={() => this.setState({lowFlag:true})} style={{marginLeft:'auto', cursor:'pointer'}} />}
                                    </h4>
                                  </div>
                                  { lowFlag ?
                                    <LOWGraph
                                      simulationType={simulationType}
                                      lowInfo={financeRptInfo.engines.engine_data[label].life_on_wing_plots}
                                      lowInfoSimulated={simulationType === '3' ? simulateFinanceRptInfo.engines.engine_data[label].life_on_wing_plots:{}}
                                    />:null
                                  }
                                </div>
                                <Paper style={{border:'1px solid #d7d7d7', padding:'10px'}} >
                                  <EngineEventInfo
                                    engine={financeRptInfo.engines.engine_data[label]}
                                    simulateEngine={ simulationType === '3' ? simulateFinanceRptInfo.engines.engine_data[label]:{}}
                                    simulationType={simulationType}
                                    financeError={financeError && financeError.engines && financeError.engines[label]}
                                    updateErrorEngineField={this.updateErrorEngineField}
                                    onFieldChange={this.onFieldChange}
                                    label={label}
                                  />
                                  <div className="engine-events-table">
                                    <Table className='mui-styled-table'>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell style={{width:'16.67%'}}>Shop Visits	</TableCell>
                                          <TableCell style={{width:'16.67%'}}>Shop Visit Date	</TableCell>
                                          <TableCell style={{width:'16.67%'}}>Esc. MR Rate as of Shop Visit Date</TableCell>
                                          <TableCell style={{width:'16.67%'}}>Esc. Shop Visit Cost as of Shop Visit Date	</TableCell>
                                          <TableCell style={{width:'16.67%'}}>Fund Value</TableCell>
                                          <TableCell style={{width:'16.67%'}}>Balance</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {Object.keys(financeRptInfo.engines.engine_data[label].shop_visits).map((sv, svIndex) =>
                                          <EngineEventList
                                            engine={financeRptInfo.engines.engine_data[label]}
                                            engineIndex={label}
                                            shopVisitIndex={sv}
                                            index={svIndex}
                                            simulationType={simulationType}
                                            shopVisit={financeRptInfo.engines.engine_data[label].shop_visits[sv]}
                                            financeError={financeError && financeError.engines && financeError.engines[label] && financeError.engines[label]['shop_visits'] && financeError.engines[label]['shop_visits'][sv]}
                                            updateErrorEngineField={this.updateErrorEngineField}
                                            simulateShopVisit={ simulationType === '3' ? simulateFinanceRptInfo.engines.engine_data[label].shop_visits[sv]:{}}
                                            onFieldChange={this.onFieldChange}
                                          />
                                        )}
                                      </TableBody>
                                    </Table>
                                  </div>
                                </Paper>
                              </div>
                            </div>:null
                          )}
                        </div>
                        :
                        <div>
                          { Object.keys(financeRptInfo.engines.engine_data).map((label, index) =>
                            <Paper style={{borderRadius:0}} className="events-bar-chart">
                              <div className='events-bar-chart-title'>
                                <h4>Engine #{index+1}</h4>
                              </div>
                              <Fragment>
                                { simulationType === '3' ? <h5 style={{padding:'15px'}}>Without Simulation</h5>:null}
                                <Grid container spacing={2}>
                                  <Grid item md={6}>
                                    <h6 style={{padding:'0 0 0 15px'}}>PRSV Shop Visits</h6>
                                    <EngineEventBarChart label="PRSV Shop Vists" index={0} events={financeRptInfo.engines.engine_data[label].shop_visits} />
                                  </Grid>
                                  <Grid item md={6}>
                                    <h6 style={{padding:'0 0 0 15px'}}>LLP Shop Visits</h6>
                                    <EngineEventBarChart label="LLP Shop Vists" index={1} events={financeRptInfo.engines.engine_data[label].shop_visits} />
                                  </Grid>
                                </Grid>
                              </Fragment>
                              {simulationType === '3' ?
                                <Fragment>
                                  <h5 style={{padding:'15px', borderTop: '1px solid #d7d7d7'}}>With Simulation</h5>
                                  <Grid container spacing={2}>
                                    <Grid item md={6}>
                                      <h6 style={{padding:'0 0 0 15px'}}>PRSV Shop Visits</h6>
                                      <EngineEventBarChart label="PRSV Shop Vists" index={0} events={simulateFinanceRptInfo.engines.engine_data[label].shop_visits} />
                                    </Grid>
                                    <Grid item md={6}>
                                      <h6 style={{padding:'0 0 0 15px'}}>LLP Shop Visits</h6>
                                      <EngineEventBarChart label="LLP Shop Vists" index={1} events={simulateFinanceRptInfo.engines.engine_data[label].shop_visits} />
                                    </Grid>
                                  </Grid>
                                </Fragment>:null
                              }
                            </Paper>
                          )}
                        </div>
                      }
                    </div>:null
                  }

                  { assemblyIndex === 'apu' && financeRptInfo.apu && Object.keys(financeRptInfo.apu).length ?
                    <div className='assembly-projection-events'>
                      {listMode?
                        <AssemblyEventInfo
                          assemblyType='apu'
                          log_id={this.props.match.params.id}
                          assemblyInfo={financeRptInfo.apu}
                          assemblyInfoSimulated={simulationType === '3' ? simulateFinanceRptInfo.apu:{}}
                          simulationType={simulationType}
                          updateErrorField={this.updateErrorField}
                          updateEventsErrorField={this.updateEventsErrorField}
                          financeError={financeError && financeError.apu}
                          onFieldChange={this.onFieldChange}
                        />:
                        <Paper style={{borderRadius:0}} className="events-bar-chart">
                          <div className='events-bar-chart-title'>
                            <h4>APU</h4>
                          </div>
                          <Grid container spacing={2}>
                            <Grid item md={ simulationType === '3' ? 6:12}>
                              { simulationType === '3' ? <h5 style={{padding:'10px'}}>Without Simulation</h5>:null}
                              <EventsBarChart label={'APU'} events={financeRptInfo.apu.events} />
                            </Grid>
                            { simulationType === '3' ? 
                              <Grid item md={6}>
                                <h5 style={{padding:'10px'}}>Without Simulation</h5>
                                <EventsBarChart label={'APU'} events={simulateFinanceRptInfo.apu.events} />
                              </Grid>:null
                            }
                          </Grid>
                          
                        </Paper>
                      }
                    </div>:null
                  }
                  { assemblyIndex === 'landing_gear' && financeRptInfo.landing_gear &&Object.keys(financeRptInfo.landing_gear).length ?
                    <div className='assembly-projection-events'>
                      {listMode ?
                        <AssemblyEventInfo
                          assemblyType='landing_gear'
                          log_id={this.props.match.params.id}
                          assemblyInfo={financeRptInfo.landing_gear}
                          assemblyInfoSimulated={simulationType === '3' ? simulateFinanceRptInfo.landing_gear:{}}
                          simulationType={simulationType}
                          updateErrorField={this.updateErrorField}
                          updateEventsErrorField={this.updateEventsErrorField}
                          financeError={financeError && financeError.landing_gear}
                          onFieldChange={this.onFieldChange}
                        />:
                        <Paper style={{borderRadius:0}} className="events-bar-chart">
                          <div className='events-bar-chart-title'>
                            <h4>Landing Gear</h4>
                          </div>
                          <Grid container spacing={2}>
                            <Grid item md={ simulationType === '3' ? 6:12}>
                              { simulationType === '3' ? <h5 style={{padding:'10px'}}>Without Simulation</h5>:null}

                              <EventsBarChart label={'Landing Gear'} events={financeRptInfo.landing_gear.events} />
                            </Grid>
                            { simulationType === '3' ? 
                              <Grid item md={6}>
                                <h5 style={{padding:'10px'}}>Without Simulation</h5>
                                <EventsBarChart label={'Landing Gear'} events={financeRptInfo.landing_gear.events} />
                              </Grid>:null
                            }
                          </Grid>
                        </Paper>
                      }                  
                    </div>:null
                  }
                </div>
              </Paper>
            </div>
            :
            <EOLProjection />
          }
          
          <Dialog
            open={this.state.regionModal}
            onClose={() => this.setState({regionModal: false})}
            aria-labelledby = "scroll-dialog-title"
            fullWidth={true}
          >
          <DialogContent style={{padding:'8px 10px'}}>
            <CountryStateSelector
              regionDetails={this.state.regionDetails}
              updateLocationWithoutGoogle={(key, value, stateFlag) => this.updateLocationWithoutGoogle(key, value, stateFlag)}
              updateRegionErrorField={this.updateRegionErrorField}
              error={regionError}
              updateErrorField={() => console.log('')  }
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => this.setState({regionModal: false})}>Cancel</Button>
            <Button color="primary" variant="contained" onClick={() => this.updateLocationWithoutGoogle('save', null, stateFlagCheck)}>Okay</Button>
          </DialogActions>
        </Dialog>
        </section>
        { pageLoader ? <PageLoader /> : null }          
      </Fragment>
    )
  }
}
export default withSnackbar(MrGraphicalReps);
