import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import { Button, Paper, Grid, TextField, Dialog, DialogContent, DialogActions, DialogTitle, Tabs, Tab } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InfiniteScroll from "react-infinite-scroll-component";
import { isMobile, isTablet } from 'react-device-detect';
import CashflowList from './CashflowList';
import CashflowListCmView from './CashflowListCmView';
import { ExportManu, PageLoader } from '../../../shared_elements';
import { STableLoader } from '../../../shared_elements/loaders';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NumericLabel from 'react-pretty-numbers';
import { getCashflowListApi, exportCashflowListApi, getCashflowCompactApi } from '../apiServices';
import { removeEmptyKey, getCurrencyFormat } from '../../../utils';
import { displayDateFormatShort } from '../../../constants';
class MrCashflow extends Component {
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader: true,
      pageLoader: false,
      cashflowList:[],
      cashflowListCv:[],
      tabIndex:0,
      perPage:40,
      currentPage:1,
      currentPageCv:1,
      totalCount:0,
      totalCountCv:0,
      resultCopy:[],
      resultCopyCv:[],
      originalCashflowList:[],
      originalCashflowListCv:[],
      years: [],
      yearsCv:[],
      nameFilter:[],
      month:[],
      monthList:[],
      index:[],
      hasMore:true,
      hasMoreCv:true,
      filter:{
        name:'',
        month:''
      },
      cmFilter:{
        reserve_account:null,
        year:null
      }
    };
    this.getCashflowListApi = getCashflowListApi.bind(this);
    this.getCashflowCompactApi = getCashflowCompactApi.bind(this);
    this.exportCashflowListApi = exportCashflowListApi.bind(this);
  }
  onFieldChange = (event, keyParam, value) => {
    const { originalCashflowList, originalCashflowListCv, filter } = this.state;
    let filterRes = [];
    let filterParams = removeEmptyKey({
      month: keyParam === 'month' ? value : filter.month,
      name: keyParam === 'name' ? value:filter.name,
    });
    if(filterParams.name){
      this.setState({filterApplied:true})
    }
    else{
      this.setState({filterApplied:false})
    }
    if(filterParams.month && filterParams.name){
      filterRes = originalCashflowList.filter((item) => item.name === filterParams.name && item.month.slice(0,4) === filterParams.month.toString())
    }else if (filterParams.month) {
      filterRes = originalCashflowList.filter((item) => item.month.slice(0,4) === filterParams.month.toString())
    }else if (filterParams.name) {
      filterRes = originalCashflowList.filter((item) => item.name === filterParams.name)
    }else{
      filterRes = originalCashflowList;
    }
    filterRes.sort((a,b) => parseInt(a.month) - parseInt(b.month));
    this.setState(prevState => ({
      ...prevState,
      cashflowList:filterRes.slice(0,40),
      resultCopy:filterRes,
      currentPage:1,
      totalCount:filterRes.length,
      filter:{
        ...prevState.filter,
        [keyParam]:value
      }
    }))
  }
  onFieldChangeCm = (event, keyParam, value) => {
    const { copyfinanceRptInfo, simulateFinanceRptInfo, simulationType} = this.props;
    const { cmFilter } = this.state;
    let queryFilter = {};
    if(keyParam === 'year'){
      if(value){
        queryFilter = {
          ...queryFilter,
          year:value.value
        }
      }
      if(cmFilter.reserve_account){
        queryFilter = {
          ...queryFilter,
          reserve_account:cmFilter.reserve_account.value
        }
      }
    }
    if(keyParam === 'reserve_account'){
      if(value){
        queryFilter = {
          ...queryFilter,
          reserve_account:value.value
        }
      }
      if(cmFilter.year){
        queryFilter = {
          ...queryFilter,
          year:cmFilter.year.value
        }
      }
    }

    this.setState(prevState => ({
      ...prevState,
      cmFilter:{
        ...prevState.cmFilter,
        [keyParam]:value
      }
    }));
    this.getCashflowCompactApi(this.props, simulationType === '3' ? {...simulateFinanceRptInfo, ...queryFilter}: {...copyfinanceRptInfo, ...queryFilter}, 'skeletonLoader')
  }

  moveNextPage = (type) => {
    const { cashflowList, cashflowListCv, resultCopy, resultCopyCv, currentPage, currentPageCv, perPage, totalCount, totalCountCv } = this.state;
    if(type === 'detail'){
      if (cashflowList.length >= totalCount) {
        this.setState({ hasMore: false });
        return;
      }
      let scrollData = JSON.parse(JSON.stringify(resultCopy));
      scrollData = scrollData.sort((a,b) => parseInt(a.month) - parseInt(b.month)).slice((currentPage*perPage), (currentPage*perPage)+perPage);
      this.setState(prevState => ({
        ...prevState,
        cashflowList: [...prevState.cashflowList, ...scrollData ],
        currentPage: (prevState.currentPage + 1)
      }));
    }else{
      if(cashflowListCv.length >= totalCountCv) {
        this.setState({ hasMoreCv: false });
        return;
      }
      let scrollDataCv = JSON.parse(JSON.stringify(resultCopyCv));
      scrollDataCv = scrollDataCv.sort((a,b) => parseInt(a.month) - parseInt(b.month)).slice((currentPageCv*perPage), (currentPageCv*perPage)+perPage);
      this.setState(prevState => ({
        ...prevState,
        cashflowListCv: [...prevState.cashflowListCv, ...scrollDataCv ],
        currentPageCv: (prevState.currentPageCv + 1)
      }));
    }
  }

  handleChange = (event, newValue) => {
    this.setState({tabIndex:newValue})
  }

  render(){
    const { skeletonLoader, pageLoader, modal, years, yearsCv, nameFilter, nameFilterCv, filter, cashflowList, cashflowListCv, hasMore, hasMoreCv, item, monthList, index, month, tabIndex, resAccDropdown, yearDropdown, cmFilter } = this.state;
    const { copyfinanceRptInfo, simulateFinanceRptInfo, simulationType} = this.props;
    const totalCredit =  cashflowList.filter(cashflow => cashflow.credit ? cashflow.credit : '').reduce((sum, current) => {
      return ( sum + current.credit)
    }, 0);
    console.log('hasMoreCv', hasMoreCv);
    return(
      <Fragment>
        <Button size="small" className="generate-report-button" onClick={() => this.getCashflowListApi(this.props, simulationType === '3' ? simulateFinanceRptInfo: copyfinanceRptInfo, 'skeletonLoader')} color="primary" variant="contained" style={ !isMobile ?  {float:"right"} : {align:'center'}}>Generate Monthly Cashflow</Button>
        { modal ?
          <Dialog
            open={modal}
            onClose={() => this.setState({modal:false})}
            aria-labelledby="scroll-dialog-title"
            fullScreen
          >
            <DialogTitle id="scroll-dialog-title">
            <Link style={{verticalAlign:'sub'}} onClick={() => this.setState({modal:false, filter:{}})}><ArrowBackIcon /></Link> Monthly Cashflow <ExportManu title="Export" files={[{title:'Excel', extension: 'xls', key:''}]} exportReportFn={(file) => this.exportCashflowListApi(this.props,simulationType === '3' ? simulateFinanceRptInfo: copyfinanceRptInfo, 'xls', filter, cmFilter)} />
            </DialogTitle>
            <DialogContent dividers={true} id="scrollableDiv">
              <div className="mr-calculator-section">
                { skeletonLoader ? <STableLoader count={5} />:
                  <div className="mr-cashflow-listing">
                    <Grid style={{marginBottom:'10px'}} alignItems='flex-end' container spacing={1}>
                      <Grid item md={6}>
                        {tabIndex === 0 ? 
                          <ul className="list-inline cashflow-filters">
                            <li className="list-inline-item">
                              <Autocomplete
                                options = {years}
                                getOptionLabel={option => option.label}
                                id="month"
                                value={filter.month ? years.find(item => item.value === filter.month):null}
                                onChange={(e, value) => this.onFieldChange(e, 'month', value ? value.value:null)}
                                renderInput={params => <TextField {...params} label="Year" margin="none" fullWidth InputLabelProps={{shrink: true}} variant="outlined" />}
                              />
                            </li>
                            <li className="list-inline-item">
                              <Autocomplete
                                options = {nameFilter}
                                getOptionLabel={option => option.label}
                                id="name"
                                value={filter.name ? nameFilter.find(item => item.value === filter.name) :null}
                                onChange={(e, value) => this.onFieldChange(e, 'name', value ? value.value:null)}
                                renderInput={params => <TextField {...params} label="Maintenance Reserve Account" margin="none" fullWidth InputLabelProps={{shrink: true}} variant="outlined" />}
                              />
                            </li>
                          </ul>:
                          <ul className="list-inline cashflow-filters">
                            <li className="list-inline-item">
                              <Autocomplete
                                options = {yearDropdown}
                                getOptionLabel={option => option.label}
                                id="year"
                                value={cmFilter.year}
                                onChange={(e, value) => this.onFieldChangeCm(e, 'year', value)}
                                renderInput={params => <TextField {...params} label="Year" margin="none" fullWidth InputLabelProps={{shrink: true}} variant="outlined" />}
                              />
                            </li>
                            <li className="list-inline-item">
                              <Autocomplete
                                options = {resAccDropdown}
                                getOptionLabel={option => option.label}
                                id="reserve_account"
                                value={cmFilter.reserve_account}
                                onChange={(e, value) => this.onFieldChangeCm(e, 'reserve_account', value)}
                                renderInput={params => <TextField {...params} label="Maintenance Reserve Account" margin="none" fullWidth InputLabelProps={{shrink: true}} variant="outlined" />}
                              />
                            </li>
                          </ul>
                        }
                      </Grid>
                      <Grid item md={6}>
                        <Tabs
                          value={tabIndex}
                          onChange={this.handleChange}
                          indicatorColor="primary"
                          textColor="primary"
                          variant="fullWidth"
                        >
                            <Tab label='Detailed View' onClick={() => this.getCashflowListApi(this.props, simulationType === '3' ? simulateFinanceRptInfo: copyfinanceRptInfo, 'skeletonLoader')}/>
                            <Tab label='Compact View' onClick={() => this.getCashflowCompactApi(this.props, simulationType === '3' ? simulateFinanceRptInfo: copyfinanceRptInfo, 'skeletonLoader')}/>
                        </Tabs>
                      </Grid>
                    </Grid>
                    
                    { tabIndex === 0 ?
                      <Paper>
                        <div>
                          {isMobile || isTablet ? null :
                            <div className="cashflow-header">
                              <Grid container spacing={0}>
                                <Grid item md={3}>
                                  <span>Month</span>
                                </Grid>
                                <Grid item md={3}>
                                  <span>Maintenance Reserve Account</span>
                                </Grid>
                                <Grid item md={2}>
                                  <span>Credit</span>
                                </Grid>
                                <Grid item md={2}>
                                  <span>Debit</span>
                                </Grid>
                                <Grid item md={2}>
                                  <span style={{float:'right'}}>Balance</span>
                                </Grid>
                              </Grid>
                            </div> }
                        </div>
                        <InfiniteScroll
                          dataLength={ cashflowList.length}
                          next={() => this.moveNextPage('detail')}
                          hasMore={hasMore}
                          loader={<h4 style={{textAlign: 'center'}}>Loading...</h4>}
                          endMessage={
                            <p style={{textAlign: 'center', padding:'5px'}}>
                              <b>Yay! You have seen it all</b>
                            </p>
                          }
                          scrollableTarget="scrollableDiv"
                        >
                          <div>
                            { cashflowList.map((item, index) =>
                              <CashflowList
                              filterApplied={this.state.filterApplied}
                                cashflowList={cashflowList}
                                item={item}
                                index={index}
                              />
                            )}
                          </div>
                        </InfiniteScroll>
                      </Paper>
                      :
                      <Paper>
                        <InfiniteScroll
                          dataLength={ cashflowListCv.length}
                          next={() => this.moveNextPage('compact')}
                          hasMore={hasMoreCv}
                          loader={<h4 style={{textAlign: 'center'}}>Loading...</h4>}
                          endMessage={
                            <p style={{textAlign: 'center', padding:'5px'}}>
                              <b>Yay! You have seen it all</b>
                            </p>
                          }
                          scrollableTarget="scrollableDiv"
                           >
                            {
                              cashflowListCv.map((item, index) =>
                              <Accordion>
                                <AccordionSummary  expandIcon={<ExpandMoreIcon/>}>
                                  <Grid container spacing={3}>
                                    <Grid item xs={6} sm={3}>
                                      Month: {item.month ? moment(item.month).format('MMM YYYY') : '--'}
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                      Total Credit: <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{item.total_credit}</NumericLabel>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                      Total Debit: <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{item.total_debit}</NumericLabel>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                      Total Balance: <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{item.total_balance}</NumericLabel>
                                    </Grid>
                                  </Grid>
                                </AccordionSummary>
                                <AccordionDetails style={{display:'block'}}>
                                {isMobile || isTablet ? null :
                                  <div className="cashflow-header">
                                    <Grid container spacing={0}>
                                      <Grid item md={4}>
                                        <span>Maintenance Reserve Account</span>
                                      </Grid>
                                      <Grid item md={3}>
                                        <span>Credit</span>
                                      </Grid>
                                      <Grid item md={3}>
                                        <span>Debit</span>
                                      </Grid>
                                      <Grid item md={2}>
                                        <span>Balance</span>
                                      </Grid>
                                    </Grid>
                                  </div>
                                }
                                <div>
                                {
                                  item.items.map((cfitem, cfindex) =>
                                  <CashflowListCmView
                                    cashflowList={cashflowListCv}
                                    item={cfitem}
                                    index={cfindex}
                                  />)
                                }
                                </div>
                              </AccordionDetails>
                            </Accordion>
                           )}
                        </InfiniteScroll>
                      </Paper>
                    }
                  </div>
                }
              </div>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => this.setState({modal:false, filter:{}})}>Okay</Button>
            </DialogActions>
          </Dialog>:null
        }
        {pageLoader ? <PageLoader /> : null}
      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(MrCashflow));
