import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import moment from 'moment'
import { displayDateFormatShort  } from '../../../constants';

const AuditList = ({audit}) => {
  return (
    <TableRow>
      <TableCell style={{width:'340px'}}>{audit.date_of_change ? moment(audit.date_of_change).format(displayDateFormatShort) : '--'}</TableCell>
      <TableCell style={{width:'340px'}}>{audit.asset_type ? audit.asset_type : '--'}</TableCell>
      <TableCell style={{width:'340px'}}>{audit.description ? audit.description : '--'}</TableCell>
    </TableRow>

  )
}
export default AuditList;
