import React, { Fragment, useState } from 'react';
import { useSnackbar } from 'notistack';
import { MentionsInput, Mention } from 'react-mentions';
import { Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { DeletePopUp }  from '../../../shared_elements'
import { globalGetService, globalPostService, globalPutService, globalDeleteService } from '../../../utils/globalApiServices';
import { getLocalStorageInfo, checkApiStatus } from '../../../utils';
const Comment = ({item, users, getResponseBack}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [deleteModal, setDeleteModal] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [userComment, setUserComment] = useState(item);
    const userInfo = getLocalStorageInfo();
    const onDeleteComment = () => {
        globalDeleteService(`maintenance/comments/${item.id}/`).then((response) => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                getResponseBack()
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        });
    }
    const onEditComment = ({}) => {
        globalPutService(`maintenance/comments/${item.id}/`, {'comment':userComment.comment}).then((response) => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                setEdit(false);
                getResponseBack();
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        });
    }
    return (
        <Fragment>
            <div>
                <MentionsInput
                    value={userComment.comment}
                    onChange={(e) => setUserComment({...userComment, comment:e.target.value})}
                    placeholder="Type @ to mention someone"
                    disabled={!isEdit}
                    className={isEdit ? 'comment-input':'comment-input-disabled'}
                    maxLength={500}
                >
                    <Mention
                        trigger="@"
                        data={users.map(item => {return{id:item.email, display:item.name}})}
                        className="comment-mention"
                    />
                </MentionsInput>
                { isEdit ?
                    <Fragment>
                        <Button disabled={userComment.comment.trim().length === 0} onClick={onEditComment} style={{marginRight:'5px'}} color="primary" variant='contained' size='small'>Update</Button>
                        <Button onClick={() => {setUserComment(item); setEdit(false)}} color="primary" variant='outlined' size='small'>Cancel</Button>
                    </Fragment>:null
                }
            </div>
            { !isEdit ?
                <ul className='list-inline comment-user-info'>
                    <li className='list-inline-item'>
                        <span>By: { userInfo && userInfo.user && userInfo.user.id === item.created_by.id ?'Me':item.created_by.name}</span>
                    </li>
                    { userInfo && userInfo.user && userInfo.user.id === item.created_by.id ?
                        <Fragment>
                            <li style={{borderLeft: '1px solid #d7d7d7', cursor:'pointer', paddingLeft:'5px'}} className='list-inline-item'>
                                <EditIcon onClick={() => {setEdit(true)}} color="primary" fontSize='small' />
                            </li>
                            <li style={{cursor:'pointer'}} className='list-inline-item'>
                                <DeleteOutlineIcon onClick={() => setDeleteModal(true)} color="secondary" fontSize='small' />
                            </li>
                        </Fragment>:null
                    }
                </ul>:null
            }
            <DeletePopUp
                modal={deleteModal}
                toggleModalFn={() => setDeleteModal(false)}
                title="Delete Comment"
                content={<h4>Are you sure you want to delete?</h4>}
                deleteRecordFn={() => onDeleteComment()}
            />
        </Fragment>
        
    )
}
export default Comment;