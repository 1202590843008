import React from 'react';
import { TableRow, TableCell, TextField, Checkbox, InputAdornment } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import { regexConstants } from '../../../constants/regEx';
const OtherCost = ({airframeEdit, item, label, onChangeOtherCost, onAddRemoveOtherCost}) => {
    return(
        <TableRow>
            <TableCell style={{width:'100px'}}>
                <Checkbox
                    disabled={!airframeEdit}
                    checked={item.include_in_mr? true:false}
                    onChange={(e) => onChangeOtherCost(e, 'include_in_mr', e.target.checked)}
                    color="primary"
                />
            </TableCell>
            <TableCell>
                <TextField
                    disabled={!airframeEdit}
                    id={`${label}_cost_type`}
                    fullWidth
                    margin="none"
                    InputLabelProps={{shrink: true}}
                    value={item.cost_type?item.cost_type:''}
                    onChange={(e) => onChangeOtherCost(e, 'cost_type', e.target.value)}
                    inputProps={{ maxLength: 25 }}
                    variant="outlined"
                />
            </TableCell>
            <TableCell>
                <TextField
                    disabled={!airframeEdit}
                    id={`${label}_cost`}
                    fullWidth
                    margin="none"
                    InputLabelProps={{shrink: true}}
                    value={item.cost?item.cost:''}
                    onChange={(e) => {regexConstants.numberWithDot.test(e.target.value) ? onChangeOtherCost(e, 'cost', e.target.value): e.preventDefault()}}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">US$</InputAdornment>
                    }}
                    inputProps={{ maxLength: 20 }}
                    variant="outlined"
                />
            </TableCell>
            { airframeEdit ?
                <TableCell>
                    <DeleteOutline style={{cursor:'pointer'}} onClick={onAddRemoveOtherCost} color="secondary" fontSize='small' />
                </TableCell>:null
            }
        </TableRow>
    )
}
export default OtherCost;