import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import { getLocalStorageInfo } from '../../../utils';
const refNoRegex = /^[0-9a-zA-Z-\/\\#|.,:]*$/;

const LogRefNo = ({match, toggleModalFn, modal, mrLogDetail, updateRefNo, isLocked}) => {
    const [reference_number, setRefNoValue] = React.useState(mrLogDetail.generalInfo.reference_number);
    const [remarks, setRemarksValue] = React.useState(mrLogDetail.generalInfo.remarks);
    const [reference_number_error, setRefNoError] = React.useState(false);
    return(
        <Dialog
            open={modal}
            onClose={() => toggleModalFn()}
            aria-labelledby="scroll-dialog-title"
            maxWidth={'md'}

        >
            <DialogTitle id="scroll-dialog-title">
                Reference No
            </DialogTitle>
            <DialogContent dividers={true}>
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                disabled={!(getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow']  && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1  && !isLocked)}
                                id='reference_number'
                                fullWidth
                                label="Reference Number"
                                margin="normal"
                                value={reference_number}
                                InputLabelProps={{shrink: true}}
                                inputProps={{ maxLength: 25 }}
                                error={reference_number_error?true:false}
                                helperText={reference_number_error ? 'Invalid Reference Number, accept alphanumeric with -/\#|.,: ' : ''}
                                variant="outlined"
                                onChange={(e) => {setRefNoValue(e.target.value); setRefNoError(false)}}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                disabled={!(getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow']  && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1  && !isLocked)}
                                id='remarks'
                                fullWidth
                                label="Remarks"
                                margin="normal"
                                value={remarks}
                                InputLabelProps={{shrink: true}}
                                multiline
                                rows={2}
                                inputProps={{ maxLength: 250 }}
                                variant="outlined"
                                onChange={(e) => setRemarksValue(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => toggleModalFn()}>Cancel</Button>
                { getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow']  && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1  && !isLocked ?
                    <Button onClick={() => {refNoRegex.test(reference_number) ? updateRefNo({is_locked:{},log_id:match.params.id, reference_number:reference_number, remarks:remarks}): setRefNoError(true)}} color="primary" variant='contained'>Save</Button>:null
                }
            </DialogActions>
        </Dialog>
    )
}

export default withSnackbar(withRouter(LogRefNo));
