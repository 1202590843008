import AuditLog from './containers';
import PageLayout from '../../hocs/PageLayout';
import UserRoleAccess from '../../hocs/UserRoleAccess';
export const auditLogRoutes = [
  {
    path: '/audit-log',
    component: PageLayout(UserRoleAccess(AuditLog,['mr_calculator', 'dashboard','R']), {apps: 'MRCalculator', layoutPhase: 1}),
    key: 'AuditLog'
  },
]
