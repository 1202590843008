import React, { Fragment } from 'react';
import { Paper, Grid, TextField, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio, InputAdornment } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { regexConstants } from '../../../constants/regEx';
const EngineInitiate = ({mrLogInfo, engineTypes, utilizationRatioList, error, onFieldChange, updateErrorField, loader}) => {
  return(
    <Paper className="mr-initiate-card">
      <h4 className="mr-initiate-card-title">
        Engine
        { mrLogInfo.metricsInfo.includes('airframe') ?
          <span>(Select Aircraft Type to enable inputs below)</span>:null
        }
      </h4>
      <div className='mr-initiate-form'>
        <Grid container alignItems='center' spacing={1}>
          <Grid item xs={12} sm={6} md={4} >
            <Autocomplete
              options = {engineTypes}
              getOptionLabel={option => option.name}
              disabled={loader ? true : mrLogInfo.metricsInfo.includes('airframe')?!mrLogInfo.airframeAndGen.aircraftType:false}
              id="engineType"
              value={mrLogInfo.engine.engineType}
              onChange={(e, value) => {onFieldChange(e, 'engineType', value);}}
              renderInput={params => <TextField required error={error.engineType ? true:false} helperText={error.engineType ? error.engineType:''} onFocus={() => {updateErrorField('engineType',''); updateErrorField('utlizationRatio', '')}} {...params} label="Engine Type"
              placeholder={loader ? "fetching the list..." : 'Select Engine Type'} margin="normal" fullWidth InputLabelProps={{shrink: true}} variant="outlined" />}
            />
          </Grid>

          
          <Grid item xs={12} sm={6} md={4}>
            <Autocomplete
              disabled={mrLogInfo.metricsInfo.includes('airframe')?!mrLogInfo.airframeAndGen.aircraftType:false}
              options = {utilizationRatioList}
              getOptionLabel={option => option.label}
              id="utlizationRatio"
              value={mrLogInfo.engine.utlizationRatio }
              onChange={(e, value) => {onFieldChange(e, 'utlizationRatio', value);}}
              renderInput={params => <TextField required error={error.utlizationRatio ? true:false} helperText={error.utlizationRatio ? error.utlizationRatio:''} onFocus={() => updateErrorField('utlizationRatio','')} {...params} label="Utilization Ratio" placeholder="Select Utilization Ratio" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant="outlined" />}
            />
          </Grid>

          { mrLogInfo.metricsInfo.includes('airframe') && mrLogInfo.airframeAndGen.aircraftType && mrLogInfo.airframeAndGen.aircraftType.no_of_engines?
            <Grid item xs={12} sm={6} md={4} >
              <label className="custom-label" style={{display:'block',fontSize:'11px', color:'#000', fontFamily:'Conv_IBMPlexSans-Medium'}}>No. of Engines</label>
              <ul className="list-inline">
                {(() => {
                  var totalEngines = new Array(mrLogInfo.airframeAndGen.aircraftType.no_of_engines).fill(1);
                  return(
                    <Fragment>
                      { totalEngines.map((count, index) =>
                        <li className="list-inline-item">
                          <FormControlLabel value="is_titled" control={<Radio size="small" checked={mrLogInfo.engine.noOfEngine === index+1} onChange={(e) => onFieldChange(e, 'noOfEngine', index+1)} color="primary" />} label={index+1} />
                        </li>
                      )}
                    </Fragment>
                  )
                })()}
              </ul>
            </Grid>:null
          }
          <Grid item xs={12} sm={6} md={4}>
            <FormControl disabled={mrLogInfo.metricsInfo.includes('airframe')?!mrLogInfo.airframeAndGen.aircraftType:false}  component="fieldset">
              <FormLabel component="legend" style={{fontSize:'11px', color:'#000', fontFamily:'Conv_IBMPlexSans-Medium',marginTop:'5px'}}>Is Engine in Mature Run?</FormLabel>
              <RadioGroup row aria-label="position" name="position" defaultValue="top">
                <FormControlLabel value="enable_mature_run" control={<Radio size="small" checked={mrLogInfo.engine.enable_mature_run?false:true} onChange={(e) => onFieldChange(e, 'enable_mature_run', false)} color="primary" />} label={"No"} />
                <FormControlLabel value="enable_mature_run" control={<Radio size="small" checked={mrLogInfo.engine.enable_mature_run?true:false} onChange={(e) => onFieldChange(e, 'enable_mature_run', true)} color="primary" />} label={"Yes"} />
              </RadioGroup>
            </FormControl>

            <label className="custom-label" ></label>
            
          </Grid>
        </Grid>
        
        {
          mrLogInfo.engine.engineType ?
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="utilization_hours"
                label="Assumed Utilization Hours"
                fullWidth
                margin="normal"
                value={mrLogInfo.engine.utilization_hours}
                InputLabelProps={{shrink: true}}
                inputProps={{ maxLength: 6 }}
                error={error.utilization_hours ? true:false}
                helperText={error.utilization_hours ? error.utilization_hours : ''}
                onChange={(e) => {regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 720 ? onFieldChange(e, 'utilization_hours', e.target.value): e.preventDefault()}}
                onFocus={() => updateErrorField('utilization_hours','')}
                InputProps={{
                  endAdornment: <InputAdornment position="end">FH</InputAdornment>
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="utilization_cycles"
                label="Assumed Utilization Cycles"
                fullWidth
                margin="normal"
                disabled
                value={mrLogInfo.engine.utilization_cycles}
                InputLabelProps={{shrink: true}}
                inputProps={{ maxLength: 6 }}
                error={error.utilization_cycles ? true:false}
                helperText={error.utilization_cycles ? error.utilization_cycles : ''}
                onChange={(e) => {regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'utilization_cycles', e.target.value): e.preventDefault()}}
                onFocus={() => updateErrorField('utilization_cycles','')}
                InputProps={{
                  endAdornment: <InputAdornment position="end">FC</InputAdornment>
                }}
                variant="outlined"
              />
            </Grid>
          </Grid> :null
        }
      </div>
    </Paper>
  )
}
export default EngineInitiate;
