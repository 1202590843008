import Login from './containers/Login';
import LessorSelection from './containers/LessorSelection';
import UserLogged from '../../hocs/UserLogged'
export const authRoutes = [
  {
    path: '/',
    component: UserLogged(Login),
    key: 'login',
    apps:'Auth'
  },
  {
    path: '/login',
    component: UserLogged(Login),
    key: 'login1',
    apps:'Auth'
  },
  {
    path: '/select-lessor',
    component: UserLogged(LessorSelection),
    key: 'select-lessor',
    apps:'Auth'
  }
]
