import React, { Fragment, Component } from 'react';
import { MixpanelConsumer } from 'react-mixpanel';
import { Button, Dialog, DialogContent, DialogTitle, Grid, } from '@material-ui/core'
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { getLocalStorageInfo, getGlobalCookie, authorizeAutoLogin, eraseGlobalCookie } from '../utils';
import { DesktopHeader, MobileHeader, DeskTopSidebar, SecondarySideNav } from '../shared_elements';
import contractsApp from '../assets/images/LeaseIcon.svg';
import projectsApp from '../assets/images/ProjectsIcon.svg';
import recordsApp from '../assets/images/RecordsIcom.svg';
import maintenanceApp from '../assets/images/MaintenaceIcon.svg';
import valuationApp from '../assets/images/AcumenFin-SLogo.png';
import * as actions from '../shared_elements/actions'
import config from '../config';
window.addEventListener('visibilitychange', checkLogin)
function checkLogin(){
  if(document.visibilityState || document.hasFocus){
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let location = window.location.href
    let lessorAccess = JSON.parse(getGlobalCookie('lessorAccess'))
    setTimeout(() =>{
      if(!lessorAccess){
        localStorage.clear();
        if(!location.includes('login') && !location.includes('signup') && !location.includes('password/create') && !location.includes('create-successfully') && !location.includes('reset-successfully') && !location.includes('password/reset') && !location.includes('termscondition') && !location.includes('data-policy') && !location.includes('user/activate') && !location.includes('otp-verification') && !location.includes('forgot') && !location.includes('view-shared')){
          // window.location.assign('/login');
          // window.location.reload()
        }
      }else{
        if(config.api.networkInterface.includes(lessorAccess.environment)){
          if(lessorAccess.id){
            if(!getLocalStorageInfo() || (getLocalStorageInfo() && !getLocalStorageInfo().defaultLessor)){
               authorizeAutoLogin({lessor_id:lessorAccess.id})
             }else if(!location.includes('select-lessor') && !location.includes('otp')){
               if(userInfo && userInfo.defaultLessor && parseInt(lessorAccess.id) !== userInfo.defaultLessor.id || getLocalStorageInfo().access !== lessorAccess.access){
                 authorizeAutoLogin({lessor_id:lessorAccess.id})
               }
             }
          }else if(getLocalStorageInfo() && !getLocalStorageInfo().defaultLessor){
            // window.location.assign('/select-lessor')
          }else {
            if((userInfo && userInfo.defaultLessor == undefined) || (!userInfo && lessorAccess)){
              // getLessorsList()
            }
          }
        }
      }
    })
  }
}
export default function PageLayout(HocComponent, extraProps={}){
  class PageLayout extends Component{
    layoutMarginProps = () => {
      if(extraProps.layoutPhase){
        switch (extraProps.layoutPhase) {
          case 0:
            // Desktop with Primary Header Only
            return '50px 0 0 0'
          case 1:
            // Desktop with Primary Header + Primary Sidenav
            return '0px 0 0 70px'
          case 2:
            // Desktop with Primary Header + Primary Sidenav + Secondary Sidenav
            return '0 0 0 260px';
          default:
            return '0'
        }
      }else{
        return '0'
      }
    }
    // componentDidMount() {
    //   getLessorsList()
    // }
    render(){
      const lessorAccess = JSON.parse(getGlobalCookie('lessorAccess'))
      if(lessorAccess && lessorAccess.access && lessorAccess.id && config.api.networkInterface.includes(lessorAccess.environment)){
        return (
          <section>
            <Fragment>
              { isMobile ? <MobileHeader /> :
                <Fragment>
                  <DesktopHeader />
                  <DeskTopSidebar />
                </Fragment>

              }
            </Fragment>
            { isMobile ? null : extraProps.layoutPhase === 2 ? <SecondarySideNav />:null}
            <main style={{margin: isMobile ? '10px 0 0 0' : this.layoutMarginProps() , padding: isMobile ? '15px 15px 15px 15px' : '20px 20px 5px 20px'}}>
              <div>
                <MixpanelConsumer>
                  {mixpanel => <HocComponent mixpanel={mixpanel} {...this.props} />}
                </MixpanelConsumer>
              </div>
            </main>
            <Dialog
              open={this.props.applicationSwitcher}
              onClose={() => this.props.toggleTheAppSwitcher(false)}
              aria-labelledby="scroll-dialog-title"
              fullScreen= {true}
            >
              <DialogTitle id="scroll-dialog-title">
                Applications
                <span style={{float:'right', cursor:'pointer'}} onClick={() => this.props.toggleTheAppSwitcher(false)}><CloseIcon color="primary" /></span>
              </DialogTitle>
              <DialogContent dividers={true} className="data-upload-modal">
                <Grid container spacing={2}>
                  {[
                    {
                      name:'Lease Management',
                      url:config.domain.lease,
                      access:getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission &&  getLocalStorageInfo().user.permission.contracts ? true:false,
                      icon:contractsApp,
                      description:'Effectively manage commerical leases and contracts of the entire fleet - aircraft,engines and even sub-modules through the entire economic life of the asset'
                    },
                    {
                      name:'Projects Management',
                      url:config.domain.projects,
                      access:getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission &&  getLocalStorageInfo().user.permission.technical ? true:false,
                      icon:projectsApp,
                      description:'Comprehensive solution for planning, executing and managing Technical Projects - from Pre-Purchase to Redeliveries. '
                    },
                    {
                      name:'Records Data Room',
                      url:config.domain.records,
                      access:getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission &&  getLocalStorageInfo().user.permission.records ? true:false,
                      icon:recordsApp,
                      description:'Centralised, secure online Documents Management System (DMS) for digitised aircraft records storage retrieval and collaboration'
                    },
                    {
                      name:'Maintenance Claim Management',
                      url:config.domain.maintenance,
                      access:getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission &&  getLocalStorageInfo().user.permission.maintenance ?true:false,
                      icon:maintenanceApp,
                      description:'Seamlessiy track, execute and manage maintenance events across the fleet, forecast future events and process commercial claims. '
                    },
                    {
                      name:'fin-S',
                      beta:false,
                      url:config.domain.valuation,
                      access:getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission &&  getLocalStorageInfo().user.permission.valuation ? true : false ,
                      icon:valuationApp,
                      description:'Access values for a wide variety of aircraft, powered by expertise and experience of ISTAT and ASA Certified Appraisers.'
                    }].map((app) =>
                    <Grid item xs={12} md={4}>
                      <div className="application-card" style={{opacity:app.access ? 0.9:0.7}}>
                        <div className="application-info">
                          <h3 className="flex-centered">
                            <img width={40} src={app.icon} alt=""/>
                            <span>
                              {app.name}
                              {app.beta ?<sup style={{color:'rgb(241, 9, 9)'}}> BETA</sup>:null}
                            </span>
                          </h3>
                          <p>
                            {app.description}
                          </p>
                        </div>
                        { app.access ?
                          <Button onClick={() => window.open(app.url, '_blank')} size="small" endIcon={<ArrowForwardIosIcon fontSize="small" />}>Enter</Button>:
                          <p className="restrict-access">Please contact support@sparta.aero to access this application</p>
                        }
                      </div>
                    </Grid>
                  )}
                </Grid>
              </DialogContent>
            </Dialog>
          </section>
        );
      }else{
          return <Redirect to="/login" />
      }

    }
  }
  const mapStateToProps = (state) => ({
    pltConstants: state.shareReducer.pltConstants,
    applicationSwitcher: state.shareReducer.applicationSwitcher,
  })
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      toggleTheAppSwitcher: (flag) => dispatch({type:actions.APPS_SWITCHER, payload:flag}),
    }
  }
  return connect(mapStateToProps, mapDispatchToProps)(PageLayout)
}
