import React, { Fragment } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
const GooglePlaces = ({address, handleChange, handleSelect, style, placeholder}) => {
  return(
    <Fragment>
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div style={{position:'relative'}}>
            <input
              {...getInputProps({
                placeholder: 'Search Airports ...',
                className: 'location-search-input',
                style: {position:'absolute', right:'60px', top:'10px', zIndex:'99', padding:'10px', width:'80%'}
              })}
            />
          <div className="autocomplete-dropdown-container" style={{position:'absolute', right:'60px', top:'55px', zIndex:'999', width:'83%'}}>
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </Fragment>
  )
}
export default GooglePlaces;
