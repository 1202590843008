import * as actions from './actions';
export default function( state = {
  pltConstants: [],
  apuTypes: [],
  aircraftTypes: [],
  engineTypes:[],
  lessee: [],
  lessor: [],
  operator: [],
  regions: [],
  portfolios:[],
  manufacturers:[],
  loader:false,
  applicationSwitcher:false,
}, action){
  switch (action.type) {
    case actions.PLATFORM_CONTANTS:
      return {
        ...state,
        pltConstants: action.payload
      }
    case actions.LESSOR_LIST:
      return {
        ...state,
        lessor: action.payload
      }
    case actions.LESSEE_LIST:
      return {
        ...state,
        lessee: action.payload
      }
    case actions.OPERATOR_LIST:
      return {
        ...state,
        operator: action.payload
      }
    case actions.APU_TYPES:
      return {
        ...state,
        apuTypes: action.payload
      }
    case actions.REGION_LIST:
      return {
        ...state,
        regions: action.payload
      }
    case actions.AIRCRAFT_TYPES:
      return {
        ...state,
        aircraftTypes: action.payload
      }
    case actions.ENGINE_TYPES:
      return {
        ...state,
        engineTypes: action.payload
      }
    case actions.PORTFOLIO_LIST:
      return {
        ...state,
        portfolios: action.payload
      }
    case actions.MANUFACTURER_LIST:
      return {
        ...state,
        manufacturers: action.payload
      }
    case actions.LOADER:
      return {
        ...state,
        loader: action.payload
      }
    case actions.APPS_SWITCHER:
      return {
        ...state,
        applicationSwitcher:action.payload
      }
    default:
      return state;
  }
}
