import React, { Fragment, useState } from 'react';
import NumericLabel from 'react-pretty-numbers';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import InfoIcon from '@material-ui/icons/Info';
import { Grid, Paper, Tooltip, Button, TextField, InputAdornment } from '@material-ui/core';
import { regexConstants } from '../../../constants/regEx';
import { getLocalStorageInfo, getCurrencyFormat } from '../../../utils'
const EngineSVPRSVMR = ({workScope, onFieldChange, cancelUsageUpdateFn, updateMrRate, isLocked}) => {
    const [mrEdit, setMrEdit] = useState(false);
    return(
        <Fragment>
            {mrEdit ?
                <ul className="list-inline mr-edit flex-centered">
                    <li className="list-inline-item" style={{width:'70%'}}>
                        {workScope.edited_engine_mr || workScope[workScope].edited_engine_mr == "" ?
                            <TextField
                                id="engine_mr"
                                margin="none"
                                value={workScope.edited_engine_mr}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 10 }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, e.target.value, 'edited_engine_mr') : e.preventDefault() }}
                            /> :
                            <TextField
                                id="engine_mr"
                                margin="none"
                                value={workScope.engine_mr}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 10 }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                }}
                                onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, e.target.value, 'engine_mr') : e.preventDefault() }}
                            />
                        }
                    </li>
                    { !isLocked ?
                        <li className="list-inline-item" style={{ cursor: 'pointer' }}>
                            <CancelIcon color="secondary" className="sv-card-action" fontSize="small" onClick={() => { setMrEdit(mrEdit ? false : true); cancelUsageUpdateFn('engines') }} />
                        </li> : null
                    }
                    { !isLocked ?
                        <li className="list-inline-item" style={{ cursor: 'pointer' }}>
                            <DoneIcon color="primary" className="sv-card-action" fontSize="small" onClick={() => { updateMrRate(); setMrEdit(mrEdit ? false : true) }} />
                        </li> : null
                    }
                </ul>
                :
                <Fragment>
                    {!(workScope.edited_engine_mr !== undefined && workScope.edited_engine_mr !== null) || (workScope.engine_mr == workScope.edited_engine_mr) ?
                        <div>
                            <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{workScope.engine_mr}</NumericLabel> / FH
                            <span style={{ marginLeft: '4px', cursor: 'pointer' }}>
                                {workScope.nsv ?
                                    getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1 && !isLocked ?
                                    <Tooltip title="Edit" arrow><EditIcon color="primary" fontSize="small" className="sv-edit-icon" onClick={() => setMrEdit(mrEdit ? false : true)} /></Tooltip> : null
                                    : null
                                }
                            </span>
                        </div> 
                        :
                        <div className="differentiation-values">
                            <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{workScope.edited_engine_mr}</NumericLabel> / FH
                            <span style={{ marginLeft: '4px', cursor: 'pointer' }}>
                                {!isLocked ?
                                    <Tooltip title="Edit" arrow><EditIcon color="primary" fontSize="small" className="sv-edit-icon" onClick={() => setMrEdit(mrEdit ? false : true)} /></Tooltip> : null
                                }
                            </span>
                            <p style={{color:'#ff0000', fontSize:'12px'}}>
                                <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{workScope.engine_mr}</NumericLabel>
                                <Tooltip title="Original Value" arrow>
                                    <InfoIcon style={{fontSize:'13px', position:'relative', top:'2px', left:'2px'}} />
                                </Tooltip>
                            </p>
                        </div>
                    }
                </Fragment>
            }       
        </Fragment>
    )
}
export default EngineSVPRSVMR;