import React from 'react';
import { Provider } from 'react-redux';
import { withOrientationChange } from 'react-device-detect'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Offline } from "react-detect-offline";
import { SnackbarProvider } from 'notistack';
import mixpanel from 'mixpanel-browser';
import { MixpanelProvider, MixpanelConsumer } from 'react-mixpanel';
import configureStore from './store/configureStore';
import { authRoutes } from './application/auth/authRoutes';
import { mrCalculatorRoutes } from './application/mr_calc/mrCalculatorRoutes';
import { settingsRoutes } from './application/settings/settingsRoutes';
import { auditLogRoutes } from './application/audit_log/auditLogRoutes'; 
import PrivateRoute from './hocs/PrivateRoute';
import { PageNotFound } from './shared_elements';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
const store = configureStore();
mixpanel.init("99a9efc96701a1878470e87d13639e93");
const theme = createMuiTheme({
  typography: {
    fontFamily: 'Conv_IBMPlexSans-Regular',
  }
});
const App = ({isLandscape, isPortrait}) => {
  return (
    <Provider store={store}>
      <div id="back-to-top-anchor" />
      <MixpanelProvider mixpanel={mixpanel}>
        <SnackbarProvider maxSnack={3} autoHideDuration={5000}>
          <ThemeProvider theme={theme}>
            <Offline><span style={{position:'fixed', top:'0', left:'0', right:'0', margin:'0 auto', width: '300px', background:'yellow', zIndex:999999, textAlign:'center'}}>You are offline currently</span></Offline>
            <Router>
              <Switch>
                { authRoutes.map(({path, component, key}, index) =>
                  <Route exact={true} path={path} component={component} key={key}/>
                )}
                {[ ...mrCalculatorRoutes, ...settingsRoutes, ...auditLogRoutes].map(({path, component, key}, index)  =>
                  <PrivateRoute exact={true} path={path} component={component} key={key}/>
                )}
                <Route exact={true} path="*" component={PageNotFound} />
              </Switch>
            </Router>
          </ThemeProvider>
        </SnackbarProvider>
      </MixpanelProvider>
    </Provider>
  );
}
export default withOrientationChange(App)
