export const rolesHd = [
  { label: 'Role Type', sortOption: false },
  { label: 'User(s)', sortOption: false },
  { label: 'Action', sortOption: false, actionCell: true }
];
export const usersHd = [
  { label: 'Name', sortOption: false },
  { label: 'Email', sortOption: false },
  { label: 'Designation', sortOption: false },
];
export const permissionHd = [
  {label:'', sortOption:false},
  {label:'View', sortOption:false},
  {label:'Create', sortOption:false},
  {label:'Edit', sortOption:false},
]
export const users = []
