import React, { Fragment } from 'react';
import { Tooltip } from '@material-ui/core';
import airframeIcon from '../../../assets/images/icon_Aircraft.png';
import engineIcon from '../../../assets/images/icon_engine.png';
import apuIcon from '../../../assets/images/apu_icon.png';
import lgIcon from '../../../assets/images/lg_icon.png';
import DoneIcon from '@material-ui/icons/Done';
const AssemblySelection = ({mrLogInfo, onFieldChange}) => {
  const handleAssemblySelection = (assembly) => {
    onFieldChange('e', 'metricsInfo', assembly)
    onFieldChange('e', 'metricsInfo', 'airframe')
  }
  return(
    <Fragment>
      <p className="metrics">Select the assembly to include</p>
      <ul className="list-inline">
        <li className="list-inline-item" onClick={(e) => { mrLogInfo.metricsInfo.includes('airframe') && (mrLogInfo.metricsInfo.includes('apu') || mrLogInfo.metricsInfo.includes('lg')) ? e.preventDefault() :onFieldChange(e, 'metricsInfo', 'airframe')}}>
          <span className="assembly-icon">
            <Tooltip title="Aircraft" arrow>
              <img src={airframeIcon} alt="Airframe" />
            </Tooltip>
            { mrLogInfo.metricsInfo.includes('airframe') ?
              <span className="overlay">
                <DoneIcon />
              </span>:null
            }
          </span>
        </li>
        <li className="list-inline-item" onClick={(e) => onFieldChange(e, 'metricsInfo', 'engine')}>
          <span className="assembly-icon">
            <Tooltip title="Engine" arrow>
              <img src={engineIcon} alt="Engine" />
            </Tooltip>
            { mrLogInfo.metricsInfo.includes('engine') ?
              <span className="overlay">
                <DoneIcon />
              </span>:null
            }
          </span>
        </li>
        <li className="list-inline-item" onClick={(e) => { mrLogInfo.metricsInfo.includes('airframe') ? onFieldChange(e, 'metricsInfo', 'apu'): handleAssemblySelection('apu') }  }>
          <span className="assembly-icon">
            <Tooltip title="APU" arrow>
              <img src={apuIcon} alt="APU" />
            </Tooltip>
            { mrLogInfo.metricsInfo.includes('apu') ?
              <span className="overlay">
                <DoneIcon />
              </span>:null
            }
          </span>
        </li>
        <li className="list-inline-item" onClick={(e) => { mrLogInfo.metricsInfo.includes('airframe') ?  onFieldChange(e, 'metricsInfo', 'lg'):handleAssemblySelection('lg')}}>
          <span className="assembly-icon">
            <Tooltip title="Landing Gear" arrow>
              <img src={lgIcon} alt="Landing Gear" />
            </Tooltip>
            { mrLogInfo.metricsInfo.includes('lg') ?
              <span className="overlay">
                <DoneIcon />
              </span>:null
            }

          </span>
        </li>
      </ul>
    </Fragment>
  )
}
export default AssemblySelection;
