import React, { Fragment } from 'react';
import { TableRow, TableCell, Tooltip, MenuItem, Menu } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import BlockIcon from '@material-ui/icons/Block';
const MRCalculatorLogs = ({roleType}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return(
    <Fragment>
      <TableRow hover tabIndex={-1}>
        <TableCell><h4>CommVerge Logs</h4></TableCell>
        <TableCell>
          <Tooltip title="Access" arrow>
            <CheckCircleIcon style={{color:'#006400'}} />
          </Tooltip>
        </TableCell>
        <TableCell>
          <Tooltip title={['MR Calculator Manager', 'Lessor Administrators'].includes(roleType) ? "Access": 'No Access'} arrow>
            {!['MR Calculator Manager', 'Lessor Administrators'].includes(roleType) ?
              <CancelIcon style={{color:'#8B0000'}} />:<CheckCircleIcon style={{color:'#006400'}} />
            }
            </Tooltip>
        </TableCell>
        <TableCell>
          <Tooltip title={['MR Calculator Manager', 'Lessor Administrators'].includes(roleType) ? "Access": 'No Access'} arrow>
            {!['MR Calculator Manager', 'Lessor Administrators'].includes(roleType) ?
              <CancelIcon style={{color:'#8B0000'}} />:<CheckCircleIcon style={{color:'#006400'}} />
            }
            </Tooltip>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}
export default MRCalculatorLogs;
