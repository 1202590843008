import React, { Fragment } from 'react';
import { Paper, Grid, TextField, InputAdornment, Tooltip, Typography, Radio, FormControlLabel, RadioGroup, FormLabel, FormControl } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';
import { regexConstants } from '../../../constants/regEx';
import { CustomToolbar } from '../../../shared_elements';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
const LGInitiate = ({ mrLogInfo, pltConstants, error, onFieldChange, updateErrorField, overhualFn, limit, updateDateOfManufacture, overhaulDateFlag, radioDom, radioDol, radioCheckLg }) => {
  const updateDateOfLastOvehaul = (e) => {
    regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'csn', e.target.value) : e.preventDefault()
    if (mrLogInfo.lgAndGen.csn_at_lsv) {
      // do nothing
    } else {
      e.target.value >= limit ? radioDol('dol') : radioDom('dom')
      e.target.value < limit && updateErrorField('dateOfLastOverhualLg', '')
    }

  }
  const updateRadio = (value) => {
    radioDom(value)
    updateErrorField('csnAndDOM', '')
    onFieldChange(value, 'csn', 0)
    if (error.dateOfLastOverhualLg) {
      updateErrorField('dateOfLastOverhualLg', '')
    }
    if (error.csn_at_lsv) {
      updateErrorField('csn_at_lsv', '')
    }
  }
  return (
    <Paper className="mr-initiate-card">
      <h4 className="mr-initiate-card-title">
        Landing Gear
        <span>(Select Aircraft Type to enable inputs below)</span>
      </h4>
      <div className='mr-initiate-form'>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={8}>
            <FormControl disabled={!mrLogInfo.airframeAndGen.aircraftType} component="fieldset">
              <RadioGroup aria-label="dom" name="dom" row >
                <FormControlLabel value={radioCheckLg} control={<Radio size="small" color="primary" checked={radioCheckLg === 'dom' ? true : false} onClick={() => updateRadio('dom')} />} label="Date of Manufacture" />
                <FormControlLabel value={radioCheckLg} control={<Radio size="small" color="primary" checked={radioCheckLg === 'dol' ? true : false} onClick={() => updateRadio('dol')} />} label="Date of Last Overhaul" />
              </RadioGroup>
            </FormControl>
            {error.csnAndDOM?<p style={{ fontSize: '12px', color: '#f44335' }}>{error.csnAndDOM}</p> : null}
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              id="csn"
              label={
                <div>
                  Cycle Since New
                  <Tooltip title={`When CSN is greater than or equal to ${limit}, Date of Manufacture toggles to Date of the Last Overhaul.`} placement="top" >
                    <InfoIcon fontSize="small" style={{ verticalAlign: 'bottom', marginLeft: '3px' }} />
                  </Tooltip>
                </div>
              }
              fullWidth
              margin="normal"
              disabled={!mrLogInfo.airframeAndGen.aircraftType}
              value={mrLogInfo.lgAndGen.csn}
              InputLabelProps={{ shrink: true, style: { pointerEvents: "auto" } }}
              inputProps={{ maxLength: 6 }}
              InputProps={{
                endAdornment: <InputAdornment position="end">FC</InputAdornment>
              }}
              error={radioCheckLg == 'dom' && error.csnAndDOM ? true : false}
              onFocus={() => updateErrorField('csnAndDOM', '')}
              onChange={(e) => updateDateOfLastOvehaul(e)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                disabled={!mrLogInfo.airframeAndGen.aircraftType}
                required={radioCheckLg == "dol" ? true : false}
                margin="normal"
                id="dom"
                label={radioCheckLg == 'dom' ? "Date of Manufacture" : 'Date of Last Overhaul'}
                format={fieldDateFormat}
                fullWidth
                disableFuture
                minDate={radioCheckLg == "dom" ? moment().subtract(50, 'years') : moment().subtract(10, 'years')}
                maxDate={moment()}
                InputLabelProps={{ shrink: true }}
                value={mrLogInfo.lgAndGen.dateOfLastOverhual ? mrLogInfo.lgAndGen.dateOfLastOverhual : null}
                error={error.csnAndDOM || error.dateOfLastOverhualLg ? true : false}
                helperText={error.dateOfLastOverhualLg ? error.dateOfLastOverhualLg : ''}
                onChange={(data, value) => updateDateOfManufacture('dateOfLastOverhual', data ? moment(data).format(backendDateFormat) : data, 'lgAndGen')}
                onFocus={() => updateErrorField(radioCheckLg == "dom" ? 'csnAndDOM' : "dateOfLastOverhualLg", '')}
                onOpen={() => updateErrorField(radioCheckLg == "dom" ? 'csnAndDOM' : 'dateOfLastOverhualLg', '')}
                inputVariant="outlined"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                disabled={!mrLogInfo.airframeAndGen.aircraftType}
                margin="normal"
                id="as_of_date"
                label="As of Date"
                format={fieldDateFormat}
                fullWidth
                minDate={moment(mrLogInfo.lgAndGen.dateOfLastOverhual)}
                disableFuture
                InputLabelProps={{ shrink: true }}
                value={mrLogInfo.lgAndGen.as_of_date ? mrLogInfo.lgAndGen.as_of_date : null}
                error={error.as_of_date ? true : false}
                helperText={error.as_of_date ? error.as_of_date : ''}
                onChange={(data, value) => updateDateOfManufacture('as_of_date', data ? moment(data).format(backendDateFormat) : data, 'lgAndGen')}
                onFocus={() => updateErrorField("as_of_date", '')}
                onOpen={() => updateErrorField("as_of_date", '')}
                inputVariant="outlined"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          {
            radioCheckLg == "dol" ?
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  disabled={!mrLogInfo.airframeAndGen.aircraftType}
                  required
                  id="csn_at_lsv"
                  label="CSN @ LSV"
                  fullWidth
                  margin="normal"
                  value={mrLogInfo.lgAndGen.csn_at_lsv}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 6 }}
                  error={error.csn_at_lsv ? true : false}
                  helperText={error.csn_at_lsv ? error.csn_at_lsv : ''}
                  onFocus={() => updateErrorField('csn_at_lsv', '')}
                  onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'csn_at_lsv', e.target.value) : e.preventDefault() }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">FC</InputAdornment>
                  }}
                  variant="outlined"
                />
              </Grid> : null
          }
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              disabled={!mrLogInfo.airframeAndGen.aircraftType}
              required
              id="cycleUtlization"
              label="Assumed Utilization"
              fullWidth
              margin="normal"
              value={mrLogInfo.lgAndGen.cycleUtlization}
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 6 }}
              error={error.lg_hourlyUtlization ? true : false}
              helperText={error.lg_cycleUtlization ? error.lg_cycleUtlization : ''}
              onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'cycleUtlization', e.target.value) : e.preventDefault() }}
              onFocus={() => updateErrorField('lg_cycleUtlization', '')}
              InputProps={{
                endAdornment: <InputAdornment position="end">FC</InputAdornment>
              }}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </div>
    </Paper>
  )
}
export default LGInitiate;
