import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { Container, Grid, Tooltip, IconButton, TextField, Badge, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import UserDropDownMenu from './UserDropDownMenu';
import WhatsNewCompleteList from './WhatsNewCompleteList';
import FAQsList from './FAQsList';
import * as actions from '../actions';
import ReportAnIssue from './ReportAnIssue';
import PageLoader from './PageLoader';
import { getLocalStorageInfo, eraseGlobalCookie,clearCookie, getLessorsList, checkApiStatus } from '../../utils';
import { globalGetService } from '../../utils/globalApiServices';
import { trackLogOut } from '../../utils/mixpanel'
import { getWhatsNewFeaturesListApi, getFaqsListApi, getHeaderCartCountApi } from '../apiServices';
import { lessorUpdateApi } from '../../application/auth/apiServices'
import commmverge from '../../assets/images/image003.jpg';
import config from '../../config';
import Cookies from 'universal-cookie'
const cookies = new Cookies();
class DesktopHeader extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      formSubmitLoader:false,
      whtsNewModal:false,
      whtsNewList:[],
      faqsModal:false,
      faqsList:[],
      headerCount:{},
      applicationSwitcher:false,
      lessorList:[]
    }
    this.getWhatsNewFeaturesListApi = getWhatsNewFeaturesListApi.bind(this);
    this.getFaqsListApi = getFaqsListApi.bind(this);
    this.lessorUpdateApi = lessorUpdateApi.bind(this);
    this.getHeaderCartCountApi = getHeaderCartCountApi.bind(this);
  }
  componentDidMount(){
    this.getHeaderCartCountApi();
    globalGetService('/sso-lessor/list/')
    .then(response => {
      if(checkApiStatus(response)){
        let userInfo = getLocalStorageInfo();
        userInfo = {
          ...userInfo,
          lessor_list: response.data.data
        }
        this.setState({lessorList:response.data.data})
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
      }
    })


  }
  logout = () => {
    localStorage.clear();
    clearCookie('lessorAccess', '.sparta.aero', '/')
    eraseGlobalCookie('lessorAccess')
    eraseGlobalCookie('domain')
    eraseGlobalCookie('userName')
    cookies.remove('lessorAccess' , {path: "/", domain: "sparta.aero"})
    this.props.history.push('/login')
    trackLogOut()
  }
  toggleTheAppSwitcher = (flag) => {
    this.setState({applicationSwitcher:flag})
  }
  render(){
    const { whtsNewModal, whtsNewList, faqsModal, faqsList, headerCount, pageLoader, formSubmitLoader, lessorList } = this.state;;
    return(
      <Fragment>
        <header className="desktop-header">
          <Container maxWidth={false}  style={{padding:'0px 5px'}}>
            <Grid container  spacing={0} alignItems="center" justify="center">
              <Grid item xs={5}>
                <ul className="list-inline app-logo-search flex-centered">
                  <li className="list-inline-item app-logo">
                    <Link to="/logs">
                      <img src={'https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/sparta_logo.png'} alt="Sparta" />
                    </Link>
                  </li>
                  <li>
                    <Button
                      className="app-switcher"
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={() => this.props.toggleTheAppSwitcher(true)}
                      endIcon={<ArrowDropDownIcon />}
                    >
                      CommVerge
                    </Button>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={7}>
                <ul className="list-inline header-right-nav flex-centered">
                  <li className="list-inline-item">
                    <WhatsNewCompleteList />
                  </li>
                  <li className="list-inline-item">
                    <ReportAnIssue type="head" />
                  </li>
                  <li className="list-inline-item">
                    <Tooltip title="FAQs" arrow>
                        <IconButton className="faq-icon" size="small" color="primary" onClick={() => this.getFaqsListApi(this.props)}>
                          <QuestionAnswerIcon color="primary" fontSize="small" />
                        </IconButton>
                    </Tooltip>
                  </li>
                  <li className="list-inline-item">
                    <Tooltip title="Notifications" arrow>
                      <IconButton size="small" color="primary">
                        <a href={`${config.domain.subDomian}notifications`} target="_blank">
                          <Badge max={99} badgeContent={headerCount.notification_count? headerCount.notification_count:0} color="secondary">
                            <NotificationsActiveIcon color="primary" fontSize="small" />
                          </Badge>
                        </a>
                      </IconButton>
                    </Tooltip>
                  </li>
                  { getLocalStorageInfo() ?
                    <li className="list-inline-item" style={{width:'200px'}}>
                      <Autocomplete
                        options = {lessorList}
                        clearOnBlur={false}
                        getOptionLabel={option => option.name}
                        disableClearable={true}
                        filterSelectedOptions={true}
                        value={{id:getLocalStorageInfo() && getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.id ? getLocalStorageInfo().defaultLessor.id : ''  , name: getLocalStorageInfo() && getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.name ? getLocalStorageInfo().defaultLessor.name:""}}
                        onChange={(e, data) => this.lessorUpdateApi(this.props, {lessor_id:data.id})}
                        renderInput={params => <TextField {...params} margin="none" fullWidth InputLabelProps={{shrink: true}} />}
                      />
                  </li>:null
                  }

                  <li className="list-inline-item user-dropdown">
                    <UserDropDownMenu
                      logout={this.logout}
                    />
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Container>
        </header>
        { faqsModal ?
            <FAQsList medialLink={this.state.medialLink} toggleModalFn={() => this.setState({faqsModal:false})} modal={faqsModal} faqsList={faqsList} />:null
          }
        { pageLoader || formSubmitLoader ? <PageLoader/>:null }
        <div style={{height:'45px'}}></div>
      </Fragment>
    )
  }
}
const mapStateToProps = state => ({
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    toggleTheAppSwitcher: (flag) => dispatch({type:actions.APPS_SWITCHER, payload:flag}),
  }
}
export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(DesktopHeader)))
