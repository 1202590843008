import React from 'react';
import { Container, Grid, Button} from '@material-ui/core';
import AuthImageSlider from './AuthImageSlider';
import SpartaLogo from '../../../assets/images/sparta_logo.png';
import config from '../../../config';
export default function BackBanner({children}) {
  return (
    <section className="auth-section">
      <Container maxWidth="lg">
        <div className="auth-main-wrapper">
          <Grid container spacing={0}>
            <Grid item md={6}>
              <div className="auth-left-blk">
              <div className="list-inline--solution">
                  <span className="list-left-item">
                    <img className="sparta-logo" src={SpartaLogo} alt="Sparta Logo" />
                  </span>
                  {
                    !window.location.href.includes('select-lessor') ?
                    <div className="auth-cta">
                    <ul className="list-inline">
                      <li className="list-inline-item ">
                        <Button className="list-inline-item-solutions" variant="outlined"><a href={`${config.domain.subDomian}solutions`} target="_blank" size="small" >Solutions</a></Button>
                      </li>
                    </ul>
                  </div>
                    :null
                  }
                </div>
                {children}
              </div>
            </Grid>
            <Grid item md={6}>
              <div className="auth-right-blk">
                <AuthImageSlider/>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </section>
  )
}
