import React, { Fragment } from 'react';
import ViewListIcon from '@material-ui/icons/ViewList';
import BarChartIcon from '@material-ui/icons/BarChart';
const ListAnalyticSW = ({toggleViewMode, listMode}) => {
    return(
        <span className='list-analytic-sw'>
            <span className='active'>
                <ViewListIcon onClick={() => toggleViewMode(true)} color={listMode ? 'primary':'default'} />
            </span>
            <span>
                <BarChartIcon onClick={() => toggleViewMode(false)} color={!listMode ? 'primary':'default'} />
            </span>
        </span>
    )
}
export default ListAnalyticSW;