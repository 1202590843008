import React, { Component, Fragment } from 'react';
import { withSnackbar } from 'notistack';
import { Footer, BackBanner, LoginForm } from '../components';
import { updateAuthInfoState, loginApi, lessorUpdateApi } from '../apiServices';
import { fieldValidation } from '../../../utils/formValidation';
import { regexConstants } from '../../../constants/regEx';
import DeploymentMessage from '../../../shared_elements/components/DeploymentMessage';
class Login extends Component {
  constructor(props){
    super(props);
    this.updateAuthInfoState = updateAuthInfoState.bind(this);
    this.lessorUpdateApi = lessorUpdateApi.bind(this);
    this.loginApi = loginApi.bind(this);
    this.state = {
      authInfo: {
        email: '',
        password:''
      },
      error: {},
      errorCode: {
        email:{
          0:'',
          1: 'Please enter Email',
          4: 'Please enter valid Email'
        },
        emailObj: {
          required: true,
          regexPattern: regexConstants.email
        },
        password: {
          0:'',
          1: 'Please enter Password'
        },
        passwordObj: {
          required: true,
        }
      },
      formSubmitLoader: false
    }
  }
  handleChange = (value, key) => {
    this.updateAuthInfoState(value, key);
  }
  onLogin = () => {
    const { errorCode, authInfo } = this.state;
    let validationInputs = {
      email:errorCode['email'][fieldValidation({...errorCode['emailObj'], fieldval: authInfo.email})],
      password: errorCode['password'][fieldValidation({...errorCode['passwordObj'], fieldval: authInfo.password})]
    }
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      this.loginApi(this.props, this.state.authInfo);
    }else{
      this.setState({error: validationInputs});
    }
  }
  render(){
    const { authInfo, error, formSubmitLoader } = this.state;
    return(
      <Fragment>
        <DeploymentMessage marginbottom="0px"/>
        <BackBanner>
          <div className="auth-form-wrapper">
            <h2 className="form-title">Login to your account</h2>
            <LoginForm
              error={error}
              authInfo={authInfo}
              handleChange={this.handleChange}
              onLogin={this.onLogin}
              formSubmitLoader={formSubmitLoader}
            />
          </div>
        </BackBanner>
        <Footer />
      </Fragment>
    )
  }
}

export default withSnackbar(Login);
