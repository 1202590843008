import React from 'react';
import CountUp from 'react-countup';
import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more";
import solidGauge from "highcharts/modules/solid-gauge";
import HighchartsReact from "highcharts-react-official";
highchartsMore(Highcharts);
solidGauge(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
const GaugeChart = ({unit, actual, min, max}) => {
    const interval = Math.round((max-min)/5);
    let colorBands = 
    [{
        from: min,
        to: min+interval,
        color: '#DF5353', // Red
        thickness: 10
    }, {
        from: min+interval,
        to: min+(2*interval),
        color: '#DDDF0D', // yellow
        thickness: 10
    }, {
        from: min+(2*interval),
        to: max,
        color: '#55BF3B', // Green
        thickness: 10
    }];
    const options = {
        chart: {
            type: 'gauge',
            plotBackgroundColor: null,
            plotBackgroundImage: null,
            plotBorderWidth: 0,
            plotShadow: false,
            height: 220
        },
        title: { text: '' },
        pane: {
            startAngle: -90,
            endAngle: 89.9,
            background: null,
            center: ['50%', '75%'],
            size: '110%'
        },
        yAxis: {
            min: min,
            max: max,
            tickPosition: 'inside',
            tickLength: 20,
            tickWidth: 0,
            minorTickInterval: null,
            labels: {
                enabled:false,
            },
            plotBands: colorBands
        },
        tooltip: {
            enabled: false,
            formatter:function(){return false}
        },
        series: [{
            name: 'MR Rate',
            data: [actual],
            tooltip: {
                enabled: false,
                formatter:function(){return false}
            },
            dataLabels: {
                enabled:false
            },
            dial: {
                radius: '80%',
                backgroundColor: 'white',
                baseWidth: 12,
                baseLength: '0%',
                rearLength: '0%'
            },
            pivot: {
                backgroundColor: 'white',
                radius: 0
            }
    
        }],
        exporting:{
            buttons:{
                contextButton:{
                    menuItems:['downloadPNG', 'downloadPDF']
                }
            }
        },
        credits: {
            enabled: false
        },
    }
    return(
        <div className='gauge-chart'>
            <span className='min-value'>
                <span className="amount">US${min}</span><br/>
                <span className="unit">Minimum MR</span>
            </span>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
            <span className='gauge-title'>
                <span className="amount">US$
                    <CountUp end={actual} /> <span className="unit-type">{unit}</span>
                </span><br/>
                <span className="unit">Blended MR</span>
            </span>
            <span className='max-value'>
                <span className="amount">US${max}</span>
                <br/> 
                <span className="unit">Maximum MR</span>
            </span>
        </div>
    )
}
export default GaugeChart;