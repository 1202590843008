import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Dialog, DialogContent, DialogActions, DialogTitle, Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import NumericLabel from 'react-pretty-numbers';
import { LabelValueCard } from '../../../shared_elements';
import { getCurrencyFormat } from '../../../utils';
const WorkScopeModal = ({workScopeInfo, carryOverNSVFn, toggleModalFn, match}) => {
  return(
    <Dialog
      open={workScopeInfo.modal}
      onClose={toggleModalFn}
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title">
        { workScopeInfo.data.name } Run
      </DialogTitle>
      <DialogContent dividers={true}>
        <div className="workscope-moreinfo-modal">
          <div style={{marginBottom:'20px'}}>
            <h4>Performance Restoration </h4>
            <Grid container spacing={2}>
              <LabelValueCard md={6} label='MR Rate' value={<NumericLabel params={getCurrencyFormat({shortFormat:false})}>{workScopeInfo.data.engine_mr}</NumericLabel>} />
              <LabelValueCard md={6} label='Shop Visit Cost' value={<NumericLabel params={getCurrencyFormat({shortFormat:false})}>{workScopeInfo.data.engine_cost}</NumericLabel>} />
              <LabelValueCard md={12} label='Funds Collected' value={<NumericLabel params={getCurrencyFormat({shortFormat:false})}>{workScopeInfo.data.engine_fund}</NumericLabel>} />
              <LabelValueCard md={6} label='Funds Difference' value={<NumericLabel params={getCurrencyFormat({shortFormat:false})}>{workScopeInfo.data.remaining_engine_fund}</NumericLabel>} />
            </Grid>
            <FormControlLabel
              control={
                <Checkbox color="primary" checked={workScopeInfo.data.carry_forward_engine_fund_status} onChange={(e) => carryOverNSVFn({carry_forward_engine_fund_status:e.target.checked})} value="Carry Over NSV" />
              }
              label="Carry over to NSV"
            />
          </div>
          <div style={{marginBottom:'20px'}}>
            <h4>LLP Replacement</h4>
            <Grid container spacing={2}>
              <LabelValueCard md={6} label='MR Rate' value={<NumericLabel params={getCurrencyFormat({shortFormat:false})}>{workScopeInfo.data.llp_bulk_mr}</NumericLabel>} />
              <LabelValueCard md={6} label='Shop Visit Cost' value={<NumericLabel params={getCurrencyFormat({shortFormat:false})}>{workScopeInfo.data.llp_replacement_cost}</NumericLabel>} />
              <LabelValueCard md={12} label='Funds Collected' value={<NumericLabel params={getCurrencyFormat({shortFormat:false})}>{workScopeInfo.data.llp_replacement_fund}</NumericLabel>} />
              <LabelValueCard md={6} label='Funds Difference' value={<NumericLabel params={getCurrencyFormat({shortFormat:false})}>{workScopeInfo.data.llp_replacement_fund_remaining}</NumericLabel>} />
            </Grid>
            <FormControlLabel
              control={
                <Checkbox color="primary" checked={workScopeInfo.data.carry_forward_llp_fund_status} onChange={(e) => carryOverNSVFn({carry_forward_llp_fund_status: e.target.checked})} value="Carry Over NSV" />
              }
              label="Carry over to NSV"
            />
          </div>
          <Grid container spacing={2}>
            <LabelValueCard md={6} label='Total Funds Collected' value={<NumericLabel params={getCurrencyFormat({shortFormat:false})}>{workScopeInfo.data.total_fund}</NumericLabel>} />
            <LabelValueCard md={6} label='Total Shop Visit Cost' value={<NumericLabel params={getCurrencyFormat({shortFormat:false})}>{workScopeInfo.data.total_cost}</NumericLabel>} />
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={toggleModalFn}>Okey</Button>
      </DialogActions>
    </Dialog>
  )
}
export default withRouter(WorkScopeModal);
