import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Tooltip } from '@material-ui/core';
import { regexConstants } from '../../../constants/regEx';
import EditIcon from '@material-ui/icons/Edit';
import { updateLlpMrSplitApi } from '../apiServices';
import { checkApiStatus } from '../../../utils';
class LlpMrSplitModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      modal:false,
      mr_rate:'',
      mr_split:[],
      error:''
    }
    this.updateLlpMrSplitApi = updateLlpMrSplitApi.bind(this);
  }
  openLlpMrSplit = () => {
    const { llp_mr_split, llp_mr_to_consider } = this.props;
    let data = [];
    if(llp_mr_split && Object.keys(llp_mr_split).length){
      if(llp_mr_split.fan){
        data = [...data, {order:1,key:'fan', value:parseFloat(llp_mr_split.fan).toFixed(2), percentage: llp_mr_split.fan ? parseFloat(((llp_mr_split.fan/llp_mr_to_consider)*100)).toFixed(2):0}]
      }
      if(llp_mr_split.core){
        data = [...data, {order:2,key:'core', value:parseFloat(llp_mr_split.core).toFixed(2), percentage: llp_mr_split.core ? parseFloat(((llp_mr_split.core/llp_mr_to_consider)*100)).toFixed(2):0}]
      }
      if(llp_mr_split.lpt){
        data = [...data, {order:3,key:'lpt', value:parseFloat(llp_mr_split.lpt).toFixed(2), percentage: llp_mr_split.lpt ? parseFloat(((llp_mr_split.lpt/llp_mr_to_consider)*100)).toFixed(2):0}]
      }
    }else{
      data = [{order:1,key:'fan',value:0,percentage:0}, {order:2, key:'core',value:0,percentage:0}, {order:3,key:'lpt',value:0,percentage:0}]
    }
    this.setState({
      mr_split:data.sort((a,b) => a.order-b.order),
      mr_rate:parseFloat(llp_mr_to_consider),
      modal:true
    })
  }
  onFieldChange = (e, keyParam, value, index) => {
    const { mr_split, mr_rate } = this.state;
    if(keyParam === 'mr_rate'){
      let newData = [];
      mr_split.map((item) =>
        newData = [...newData, {...item, value:value?parseFloat(item.percentage*value/100).toFixed(2):0}]
      );
      this.setState({mr_rate:value,mr_split:newData});
    }else{
      if(keyParam === 'value'){
        this.setState(prevState => ({
          ...prevState,
          mr_split:prevState.mr_split.map((item, itemIndex) => itemIndex === index ? {...item, value:value, percentage: parseFloat(value/mr_rate*100).toFixed(2)}:item)
        }))
      }else{
        this.setState(prevState => ({
          ...prevState,
          mr_split:prevState.mr_split.map((item, itemIndex) => itemIndex === index ? {...item, value:parseFloat((value*mr_rate)/100).toFixed(2), percentage: value}:item)
        }))
      }
    }
    this.setState({error:''});
  }
  onSaveMrSplit = () => {
    const { mr_rate, mr_split } = this.state;
    const { shop_visit, engine_no, match } = this.props;
    let totalMrSplit = mr_split.reduce((sum, item) => sum+parseFloat(item.value),0);
    if(mr_rate){
      if(parseFloat(mr_rate).toFixed(2) == parseFloat(totalMrSplit).toFixed(2)){
        let llp_mr = {shop_visit:shop_visit,mr:parseFloat(mr_rate)}
        llp_mr = {
          ...llp_mr,
          llp_mr_split:{
            core:mr_split.find(item => item.key === 'core').value,
            fan:mr_split.find(item => item.key === 'fan').value,
            lpt:mr_split.find(item => item.key === 'lpt').value
          }
        }
        this.updateLlpMrSplitApi(this.props,{log_id:match.params.id,engine_no:engine_no, sv_update:true,llp_mr:llp_mr})
        .then(response => {
          if(checkApiStatus(response)){
            this.setState({
              modal:false,
              mr_rate:'',
              mr_split:[],
              error:''
            })
            this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
            this.props.getResponseBack();
          }
        })
      }else{
        this.setState({error:'MR Split is not Correct'})
      }
    }else{
      this.setState({error:'Please enter MR Rate'})
    }
  }
  render(){
    const { modal, mr_rate, mr_split, error } = this.state;
    return(
      <Fragment>
        { !modal ? <Tooltip title="Edit" arrow><EditIcon className='sv-edit-icon' onClick={() => this.openLlpMrSplit()} color="primary" fontSize="small" /></Tooltip>:
        <Dialog
          open={modal}
          onClose={() => this.setState({modal:false})}
          aria-labelledby="scroll-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title">
            Edit LLP MR Rates
          </DialogTitle>
          <DialogContent dividers={true} className="llp-mr-split-modal">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  label="MR Rate"
                  id="mr_rate"
                  fullWidth
                  value={mr_rate}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange(e, 'mr_rate', e.target.value) : e.preventDefault() }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <div>
                  <h4>Module-wise MR Rate</h4>
                  <table border="1" cellSpacing={0}>
                    <thead>
                      <tr>
                        <th align="left">Module</th>
                        <th align="left">Rate in $</th>
                        <th align="left">Rate in %</th>
                      </tr>
                    </thead>
                    <tbody>
                      {mr_split.map((item, index) =>
                        <tr key={item.key}>
                          <td align="left">{item.key.toUpperCase()}</td>
                          <td align="left">
                            <TextField
                              id={`value_${item.key}`}
                              fullWidth
                              value={item.value}
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange(e, 'value', e.target.value, index) : e.preventDefault() }}
                            />
                          </td>
                          <td align="left">
                            <TextField
                              id={`percentage_${item.key}`}
                              fullWidth
                              value={item.percentage}
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? this.onFieldChange(e, 'percentage', e.target.value, index) : e.preventDefault() }}
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </Grid>
            </Grid>
            {error ? <h6 className="error-msg">{error}</h6>:null}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({modal:false})} color="primary" variant="outlined">Cancel</Button>
            <Button onClick={this.onSaveMrSplit} color="primary" variant="contained">Save</Button>
          </DialogActions>
        </Dialog>
        }
      </Fragment>
    )
  }
}
export default withRouter(withSnackbar(LlpMrSplitModal));
