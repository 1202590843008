export const auditHeads = [
  {label:'Date of change', sortOption:true, id:'date_of_change'},
  {label:'Asset type affected', sortOption:true, id:'asset_type'},
  {label:'Description of changes', sortOption:true, id:'description'},
]

export const auditFilterOps = {
  asset_type:{
    inputType: 'text',
    placeholder: 'Enter Asset Type',
    title:'Asset Type'
  },
  start_date:{
    inputType: 'date',
    title: 'Start Date'
  },
  end_date:{
    inputType:'date',
    title:'End Date'
  }
}
export const auditErrorCode = {
  start_date:{
    0:'',
    1:'',
    5:'Please enter valid Date'
  },
  start_dateObj:{
    datePattern:true
  },
  end_date:{
    0:'',
    1:'',
    5:'Please enter valid Date'
  },
  end_dateObj:{
    datePattern:true
  },
}
