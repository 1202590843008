import React, { Fragment, useState, } from "react";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';
import { Grid, TextField } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { Autocomplete } from "@material-ui/lab";
import EolOutputConditions from "./EolOutputConditions";
export default function EolOutputList({editMode, simulationType, onFieldChange, eol, simulatedEol, eolIndex, openAllAcc, setOpenAllAcc, onChangeAccordian, accordianArr }) {
  return (
    <div>
      <div className="eol-projection-heading">
        <Grid container spacing={1}>
          <Grid item md={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                InputProps={{ readOnly: true }}
                label='Lease Start Date'
                margin="normal"
                id={`lease_start_date${eolIndex}`}
                format={fieldDateFormat}
                fullWidth
                disabled={true}
                InputLabelProps={{ shrink: true }}
                inputVariant="outlined"
                value={eol?.lease_start_date ? eol.lease_start_date : null}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item md={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                required
                label='Lease End Date'
                margin="normal"
                id={`lease_end_date${eolIndex}`}
                format={fieldDateFormat}
                fullWidth
                disabled={true}
                InputLabelProps={{ shrink: true }}
                inputVariant="outlined"
                value={eol?.lease_end_date ? eol.lease_end_date : null}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              options={[]}
              getOptionLabel={option => option.label}
              id={`lease_type${eolIndex}`}
              disabled={true}
              value={eol?.lease_type ? eol.lease_type : null}
              renderInput={params => <TextField required  {...params} margin="normal" label='Lease Type' fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              className='lease-duration'
              label='Lease End Term (in MO)'
              id={`lease_duration${eolIndex}`}
              fullWidth
              margin="normal"
              value={eol?.lease_duration ? eol.lease_duration : null}
              variant="outlined"
              disabled={true}
              InputLabelProps={{ shrink: true }}
              InputProps={{ readOnly: true }}
            />
          </Grid>
        </Grid>
      </div>
      {eol?.eol_conditions?.map((eolItem, conditionIndex) =>
        <EolOutputConditions
        onChangeAccordian={onChangeAccordian}
        accordianArr={accordianArr}
        openAllAcc={openAllAcc}
        setOpenAllAcc={setOpenAllAcc}
          simulationType={simulationType}
          simulatedEolItem={simulationType === '3' && simulatedEol && simulatedEol.eol_conditions[conditionIndex]? simulatedEol.eol_conditions[conditionIndex]:{}}
          eolItem={eolItem}
          eolIndex={eolIndex}
          conditionIndex={conditionIndex}
          eol={eol}
          simulatedEol={simulatedEol}
          editMode={editMode}
          onFieldChange={onFieldChange}
        />
      )}
    </div>
  )
}
