import React, { Fragment } from 'react';
import { Paper, Grid, TextField, InputAdornment, Tooltip, Radio, FormControlLabel, RadioGroup, FormLabel, FormControl } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';
import { regexConstants } from '../../../constants/regEx';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

const APUInitiate = ({ mrLogInfo, apuTypes, error, onFieldChange, updateErrorField, overhualFn, limit, overhaulDateFlag, updateDateOfManufacture, radioCheck, radioDom, radioDol }) => {
  const updateDateOfLastOvehaul = (e) => {
    e.target.value !== '' ? regexConstants.numberWithDot.test(e.target.value) && e.target.value ? onFieldChange(e, 'tsn', e.target.value) : e.preventDefault() : onFieldChange(e, 'tsn', e.target.value)
    if (mrLogInfo.apuAndGen.tsn_at_lsv) {
      // do nothing
    } else {
      e.target.value >= limit ? radioDol('dol') : radioDom('dom')
      e.target.value < limit && updateErrorField('dateOfLastOverhual', '')
    }
  }

  const updateRadio = (value) => {
    radioDom(value)
    updateErrorField(radioCheck == "dom" ? "tsnAndDom" : 'tsnAtLsvDom', '')
    onFieldChange(value, 'tsn', 0)
    if (error.dateOfLastOverhual) {
      updateErrorField('dateOfLastOverhual', '')
    }
    if (error.tsn_at_lsv) {
      updateErrorField('tsn_at_lsv', '')
    }
  }
  return (
    <Paper className="mr-initiate-card">
      <h4 className="mr-initiate-card-title">
        APU
        <span>(Select Aircraft Type to enable inputs below)</span>
      </h4>
      <div className='mr-initiate-form'>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={4}>
            <Autocomplete
              options={apuTypes}
              getOptionLabel={option => option.name}
              id="apuType"
              disabled={!mrLogInfo.airframeAndGen.aircraftType}
              value={mrLogInfo.apuAndGen.apuType}
              onChange={(e, value) => { onFieldChange(e, 'apuType', value); }}
              renderInput={params => <TextField required error={error.apuType ? true : false} helperText={error.apuType ? error.apuType : ''} onFocus={() => updateErrorField('apuType', '')} {...params} label="APU Type" placeholder="Select APU Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <FormControl disabled={!mrLogInfo.airframeAndGen.aircraftType} component="fieldset" style={{ marginTop: '20px' }}>
              <RadioGroup aria-label="dom" name="dom" row >
                <FormControlLabel value={radioCheck} control={<Radio size="small" color="primary" checked={radioCheck === 'dom' ? true : false} onClick={() => updateRadio('dom')} />} label="Date of Manufacture" />
                <FormControlLabel value={radioCheck} control={<Radio size="small" color="primary" checked={radioCheck == 'dol' ? true : false} onClick={() => updateRadio('dol')} />} label="Date of Last Overhaul" />
              </RadioGroup>
            </FormControl>
            { error.tsnAndDom ? <p style={{ fontSize: '12px', color: '#f44335' }}>{error.tsnAndDom}</p> : null}
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              id="tsn"
              label={
                <div>
                  Time Since New
                  <Tooltip title={`When TSN is greater than or equal to ${limit}, Date of Manufacture toggles to  Date of the Last Overhaul.`} placement="top" >
                    <InfoIcon fontSize="small" style={{ verticalAlign: 'bottom', marginLeft: '3px' }} />
                  </Tooltip>
                </div>
              }
              fullWidth
              margin="normal"
              disabled={!mrLogInfo.airframeAndGen.aircraftType}
              value={mrLogInfo.apuAndGen.tsn}
              InputLabelProps={{ shrink: true, style: { pointerEvents: "auto" } }}
              inputProps={{ maxLength: 6 }}
              InputProps={{
                endAdornment: <InputAdornment position="end">APU H</InputAdornment>
              }}
              error={radioCheck == "dom" && error.tsnAndDom ? true : false}
              onFocus={() => updateErrorField('tsnAndDom', '')}
              onChange={(e) => updateDateOfLastOvehaul(e)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                required={radioCheck == "dol" ? true : false}
                disabled={!mrLogInfo.airframeAndGen.aircraftType}
                margin="normal"
                id="dom"
                label={radioCheck == "dom" ? "Date of Manufacture" : "Date of Last Overhaul"}
                format={fieldDateFormat}
                fullWidth
                disableFuture
                minDate={radioCheck == "dom" ? moment().subtract(50, 'years') : moment().subtract(2, 'years')}
                maxDate={moment()}
                InputLabelProps={{ shrink: true }}
                value={mrLogInfo.apuAndGen.dateOfLastOverhual ? mrLogInfo.apuAndGen.dateOfLastOverhual : null}
                error={error.tsnAndDom || error.dateOfLastOverhual ? true : false}
                helperText={error.dateOfLastOverhual ? error.dateOfLastOverhual : ''}
                onChange={(data, value) => updateDateOfManufacture('dateOfLastOverhual', data ? moment(data).format(backendDateFormat) : data, 'apuAndGen')}
                onFocus={() => updateErrorField(radioCheck == "dom" ? "tsnAndDom" : 'dateOfLastOverhual', '')}
                onOpen={() => updateErrorField(radioCheck == "dom" ? "tsnAndDom" : 'dateOfLastOverhual', '')}
                inputVariant="outlined"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                disabled={!mrLogInfo.airframeAndGen.aircraftType}
                margin="normal"
                id="as_of_date"
                label="As of Date"
                format={fieldDateFormat}
                fullWidth
                disableFuture
                minDate={moment(mrLogInfo.apuAndGen.dateOfLastOverhual)}
                InputLabelProps={{ shrink: true }}
                value={mrLogInfo.apuAndGen.as_of_date ? mrLogInfo.apuAndGen.as_of_date : null}
                error={error.as_of_date ? true : false}
                helperText={error.as_of_date ? error.as_of_date : ''}
                onChange={(data, value) => updateDateOfManufacture('as_of_date', data ? moment(data).format(backendDateFormat) : data, 'apuAndGen')}
                onFocus={() => updateErrorField("as_of_date", '')}
                onOpen={() => updateErrorField("as_of_date", '')}
                inputVariant="outlined"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          {
            radioCheck == "dol" ?
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  required
                  disabled={!mrLogInfo.airframeAndGen.aircraftType}
                  id="tsn_at_lsv"
                  label="TSN @ LSV"
                  fullWidth
                  margin="normal"
                  value={mrLogInfo.apuAndGen.tsn_at_lsv}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 6 }}
                  error={error.tsn_at_lsv ? true : false}
                  helperText={error.tsn_at_lsv ? error.tsn_at_lsv : ''}
                  onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tsn_at_lsv', e.target.value) : e.preventDefault() }}
                  onFocus={() => updateErrorField('tsn_at_lsv', '')}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">APU H</InputAdornment>
                  }}
                  variant="outlined"
                />
              </Grid>
              : null
          }
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              required
              disabled={!mrLogInfo.airframeAndGen.aircraftType}
              id="hourlyUtlization"
              label="Assumed Utilization APU H"
              fullWidth
              margin="normal"
              value={mrLogInfo.apuAndGen.hourlyUtlization}
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 6 }}
              error={error.apu_hourlyUtlization ? true : false}
              helperText={error.apu_hourlyUtlization ? error.apu_hourlyUtlization : ''}
              onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 720 ? onFieldChange(e, 'hourlyUtlization', e.target.value) : e.preventDefault() }}
              onFocus={() => updateErrorField('apu_hourlyUtlization', '')}
              InputProps={{
                endAdornment: <InputAdornment position="end">/ Month</InputAdornment>
              }}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </div>
    </Paper>
  )
}
export default APUInitiate;
