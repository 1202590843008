import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { Link, withRouter } from 'react-router-dom';
import folderIcon from '../../assets/images/folder_icon.svg'
class SecondarySideNav extends Component {
  constructor(props){
    super(props);
    this.state = {}
  }
  componentDidMount(){

  }
  redirection = (url, uuid) => {
    // const { match } = this.props;
    // if(uuid !== match.params.uuid){
    //   this.props.history.push(url);
    //   window.location.reload();
    }
  render(){
    const { match } = this.props
    return(
      <Fragment>
          <Fragment>
            <ul className="list-unstyled secondary-sidenav">
              <li>
                <Link to="/settings/users" className={match.path.includes('settings/users') ? 'active':''}>Users</Link>
              </li>
              <li>
                <Link to="/settings/roles" className={ match.path.includes('settings/roles') ? 'active':''}>Roles</Link>
              </li>
            </ul>
          </Fragment>
      </Fragment>
    )
  }
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}
export default withRouter(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(SecondarySideNav)));
