import React, { Component, Fragment } from 'react'
import { EmptyCollection, TableListComp, FilterUIComp, ExportManu, PageLoader } from '../../../shared_elements';
import { Grid, Paper } from '@material-ui/core';
import { auditHeads, auditFilterOps, auditErrorCode } from '..';
import { AuditList } from '../components';
import { getMrAuditLogsApi, exportMrAuditLogApi } from '../apiServices';
import { convertFilterObject, removeEmptyKey, } from '../../../utils';
import { fieldValidation } from '../../../utils/formValidation';
import moment from 'moment';
import { STableLoader } from '../../../shared_elements/loaders';
import { trackActivity } from '../../../utils/mixpanel';
import DeploymentMessage from '../../../shared_elements/components/DeploymentMessage';
class AuditLog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      skeletonLoader: false,
      pageLoader: false,
      auditData: {
        list: [],
        pagination: {}
      },
      sort: '',
      sort_by: '',
      filter: {},
      applyingFilter: {},
      filterError: {},

    }
    this.getMrAuditLogsApi = getMrAuditLogsApi.bind(this)
    this.exportMrAuditLogApi = exportMrAuditLogApi.bind(this)

  }
  componentDidMount() {
    this.getMrAuditLogsApi(this.props, {}, 'skeletonLoader')
    trackActivity('Page Visited', {
      page_title: 'Audit Log'
    })
  }
  applyFilter = (keyParam, value, rangeKey) => {
    if (rangeKey) {
      this.setState(prevState => ({
        ...prevState,
        applyingFilter: {
          ...prevState.applyingFilter,
          [keyParam]: {
            ...prevState.applyingFilter[keyParam],
            [rangeKey]: value
          },
          [rangeKey]: value
        }
      }))
    } else {
      this.setState(prevState => ({
        ...prevState,
        applyingFilter: {
          ...prevState.applyingFilter,
          [keyParam]: value
        },
        filterError: {
          ...prevState.error,
          [keyParam]: ''
        }
      }))
    }
  }
  removeFilter = (keyParam) => {
    const { filter, applyingFilter } = this.state;
    let query = Object.assign({}, filter);
    delete query[keyParam];
    this.getMrAuditLogsApi(this.props, { ...query }, 'pageLoader');
    this.setState({ applyingFilter: delete applyingFilter[keyParam] })
  }
  createSortHandler = (sortField) => {
    const { sort, sort_by, filter } = this.state;
    this.setState({
      sort: sortField,
      sort_by: (sortField === sort ? sort_by === 'asc' ? 'desc' : 'asc' : 'asc')
    });
    if (sortField === sort) {
      if (sort_by === 'asc') {
        this.getMrAuditLogsApi(this.props, { ...filter, sort: sortField, sort_by: 'desc', }, 'pageLoader');
      } else {
        this.getMrAuditLogsApi(this.props, { ...filter, sort: sortField, sort_by: 'asc', }, 'pageLoader');
      }
    } else {
      this.getMrAuditLogsApi(this.props, { ...filter, sort: sortField, sort_by: 'asc', }, 'pageLoader');
    }
  }
  submitFilter = (filterOptions) => {
    const { auditData, applyingFilter, sort, sort_by } = this.state
    let validationInputs = {}
    if ('start_date' in applyingFilter) {
      validationInputs = {
        ...validationInputs,
        start_date: auditErrorCode['start_date'][fieldValidation({ ...auditErrorCode['start_dateObj'], fieldval: applyingFilter.start_date })]
      }
    }
    if ('end_date' in applyingFilter) {
      validationInputs = {
        ...validationInputs,
        end_date: auditErrorCode['end_date'][fieldValidation({ ...auditErrorCode['end_dateObj'], fieldval: applyingFilter.end_date })]
      }
    }
    if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
      this.getMrAuditLogsApi(this.props, { ...convertFilterObject(removeEmptyKey(applyingFilter), filterOptions), per_page: auditData.pagination.per_page, sort: sort, sort_by: sort_by, }, 'pageLoader')
    } else {
      this.setState({ filterError: validationInputs })
    }

  }


  exportReportFn = (file) => {
    const {filter} = this.state
    this.exportMrAuditLogApi(this.props, removeEmptyKey({...filter, download:file.extension}))
  }
  render() {
    const { auditData, pageLoader, sort, sort_by, filter, applyingFilter, skeletonLoader, filterError } = this.state;
    let filterOptions = auditFilterOps
    filterOptions = {
      ...filterOptions,
      start_date: {
        ...filterOptions.start_date,
        maxDate: applyingFilter.end_date ? moment(applyingFilter.end_date) : moment().add(25, 'years'),
        minDate: moment().subtract(25, 'years')
      },
      end_date: {
        ...filterOptions.end_date,
        maxDate: moment().add(25, 'years'),
        minDate: applyingFilter.start_date ? moment(applyingFilter.start_date) : moment().subtract(25, 'years')
      }
    }
    return (
      <div className="audit-log-section">
        <DeploymentMessage/>
        {pageLoader ? <PageLoader /> : null}
        
        {
          skeletonLoader ? <STableLoader count={3} /> :
            <Fragment>
              <Paper>
                <Grid container alignItems='center'>
                  <Grid item md={11}>
                    <FilterUIComp
                      filter={filter}
                      applyingFilter={applyingFilter}
                      removeFilter={this.removeFilter}
                      removeAllFilter={() => { this.setState({ applyingFilter: {} }) ;this.getMrAuditLogsApi(this.props, {}, 'pageLoader')}}
                      submitFilter={() => this.submitFilter(filterOptions)}
                      applyFilter={this.applyFilter}
                      filterMenu={filterOptions}
                      error={filterError}
                    />
                  </Grid>
                  <Grid item md={1}>
                    <div style={{ float: 'right' }}>
                      <ExportManu title="Export" files={[{ title: 'Excel', extension: 'xlsx', key: '' }]} exportReportFn={(file) => this.exportReportFn(file)} />
                    </div>
                  </Grid>
                </Grid>
              </Paper>
              
              <TableListComp
                heads={auditHeads}
                sort={sort}
                sort_by={sort_by}
                data={auditData.list.map((audit, index) =>
                  <AuditList
                    audit={audit}
                    key={index}
                  />
                )}
                noRecord={auditData.list.length ? null : <EmptyCollection title="No Data Found!" />}
                pagination={auditData.pagination}
                onChangePage={(event, newPage) => this.getMrAuditLogsApi(this.props, { page: newPage + 1, per_page: auditData.pagination.per_page }, 'pageLoader')}
                onChangeRowsPerPage={(event) => this.getMrAuditLogsApi(this.props, { page: 1, per_page: event.target.value }, 'pageLoader')}
                createSortHandler={this.createSortHandler}
              />
            </Fragment>
        }
      </div>
    )
  }
}

export default AuditLog;
