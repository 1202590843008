import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
const EventsBarChart = ({label, events}) => {
    const cat = Object.keys(events).map((event,index) => events[event].eventDate)
    const eventCost = Object.keys(events).map((event,index) => events[event].eventCost)
    const fundCollected = Object.keys(events).map((event,index) => events[event].fundCollected)
    const balance = Object.keys(events).map((event,index) => events[event].surPlus)
    const formatTooltip = (tooltip, x = this.x, points = this.points) => {
        let s = `<b>${x}</b>`;
        points.forEach((point) =>
          s += `<br/>${point.series.name}: ${point.y}`
        );
        return s;
    }
    const options = {
        chart: {
            type: 'column',
        },
        title: {text: ''},
        xAxis: {
            categories: cat
        },
        yAxis: {
            title: {text: ''}            
        },
        tooltip: {
            tooltip: {
                formatter: formatTooltip,
                shared: true,
            },
            shared:true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            },
            column: {
                depth: 40
            }
        },
        series: [{
            name: 'Event Cost',
            data: eventCost,
            color:'#0066FF',
            tooltip:{
                valuePrefix:'US$'
            }
        }, {
            name: 'Fund Collected',
            data: fundCollected,
            color:'#F9AC2A',
            tooltip:{
                valuePrefix:'US$'
            }
        }, {
            name: 'Balance',
            data: balance,
            color:'#23E477',
            negativeColor: 'red',
            tooltip:{
                valuePrefix:'US$'
            }
        }],
        credits: {
            enabled: false
        },
    }
    return(
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )
}
export default EventsBarChart;