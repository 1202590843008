import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {getGlobalCookie, getLocalStorageInfo, authorizeAutoLogin,  eraseGlobalCookie} from '../utils'
import { lessorUpdateApi } from '../application/auth/apiServices';
import config from '../config';
window.addEventListener('visibilitychange', checkLogin)
function checkLogin() {
  if(document.visibilityState || document.hasFocus){
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let location = window.location.href
    let lessorAccess =JSON.parse(getGlobalCookie('lessorAccess'))
    setTimeout(() =>{
      if(!lessorAccess){
        localStorage.clear();
        if(!location.includes('login') && !location.includes('signup') && !location.includes('password/create') && !location.includes('create-successfully') && !location.includes('reset-successfully') && !location.includes('password/reset') && !location.includes('termscondition') && !location.includes('data-policy') && !location.includes('user/activate') && !location.includes('otp-verification') && !location.includes('forgot') && !location.includes('view-shared')){
          // window.location.assign('/login');
          // window.location.reload()
        }
      }else{
        if(config.api.networkInterface.includes(lessorAccess.environment)){
          if(lessorAccess.id){
            if(!getLocalStorageInfo() || (getLocalStorageInfo() && !getLocalStorageInfo().defaultLessor)){
               // authorizeAutoLogin(lessorAccess)
               authorizeAutoLogin({lessor_id:lessorAccess.id})
             }else if(!location.includes('select-lessor') && !location.includes('otp')){
               if(userInfo && userInfo.defaultLessor && parseInt(lessorAccess.id) !== userInfo.defaultLessor.id || getLocalStorageInfo().access !== lessorAccess.access){
                 // authorizeAutoLogin(lessorAccess)
                 authorizeAutoLogin({lessor_id:lessorAccess.id})
               }
             }
          }else if(getLocalStorageInfo() && !getLocalStorageInfo().defaultLessor){
            // window.location.assign('/select-lessor')
          }else {
            if((userInfo && userInfo.defaultLessor == undefined) || (!userInfo && lessorAccess)){
              // getLessorsList()
            }
          }
        }
      }
    })
  }
}
export default function UserLogged(HocComponent){
  return class extends Component{
    constructor(props){
      super(props)
      this.lessorUpdateApi = lessorUpdateApi.bind(this)
    }
    componentDidMount(){
      checkLogin()
    }
    render(){
      const lessorAccess =  JSON.parse(getGlobalCookie('lessorAccess'))
      if(lessorAccess && lessorAccess.access && lessorAccess.id && config.api.networkInterface.includes(lessorAccess.environment) ){
        return(
          <Redirect to="/logs" />
        )
      }else {
        return(
           <HocComponent {...this.props} />
        )
      }
    }
  }
}
