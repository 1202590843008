import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import ErrorBoundary from './ErrorBoundary';
import reportWebVitals from './reportWebVitals';
import './assets/styles/fonts.scss';
import './assets/styles/common.scss';
import './assets/styles/layout.scss';
import './assets/styles/user.scss';
import './assets/styles/mr_calculator.scss';
import './assets/styles/material_ui_override.scss';
import './assets/styles/settings.scss';
import './assets/styles/audit_log.scss';

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
