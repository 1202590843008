
import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { imgStoragePath } from '../../constants'
export default class DeploymentMessage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            deploymentFlag: false
        }
    }
    render() {
        const { deploymentFlag } = this.state
        let marginVal = this.props.margin
        let marginbottom = this.props.marginbottom
        return (
            <>
                {
                    deploymentFlag ?
                        <Grid item sm="12">
                            <p className="deployment-info" style={this.props.marginbottom ? { marginBottom: marginbottom } : null}>
                                {/* <span >SPARTA will be under heavy maintenance activity from <h6>23rd Dec UTC 18:30 hrs to 25th Dec UTC 18:30 hrs </h6>. Upload functionalities will be impacted during these days. Apologies for the inconvenience caused.</span> */}
                                <span>Dear User, due to some unscheduled maintenance activity at our Hosting Service Provider's end, you may experience some interruptions on SPARTA. Sorry for the inconvenience. Regards - SPARTA Support Team</span>
                                <span >
                                    <img
                                        width="13"
                                        onClick={() => { this.setState({ deploymentFlag: false }) }}
                                        src={imgStoragePath + "toast_cross.png"}
                                    />
                                </span>
                            </p>
                        </Grid>
                        :
                        null}
            </>
        )
    }
}