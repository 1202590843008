import { globalGetService, globalDeleteService, globalPostService, globalPutService, globalExportService } from '../../utils/globalApiServices';
import { checkApiStatus, getLocalStorageInfo, downloadFileType } from '../../utils';
import { trackActivity } from '../../utils/mixpanel';
import moment from 'moment';
// User Management API
export function getAppsUsersApi(props, queryParams, loaderType = "pageLoader") {
  this.setState({ [loaderType]: true });
  globalGetService(`v2/get-user-list/`, { ...queryParams, application: 4 })
    .then(response => {
      this.setState({ [loaderType]: false });
      if (checkApiStatus(response)) {
        const data = response.data.data.list.sort((a, b) => a.first_name - b.first_name)
        this.setState(prevState => ({
          ...prevState,
          users: {
            ...prevState.users,
            list: (queryParams.page && queryParams.page !== 1) ? [...prevState.users.list, ...response.data.data.list] : response.data.data.list,
            pagination: response.data.data.pagination,
            active_users_count: response.data.data.active_users_count,
            inactive_users_count: response.data.data.inactive_users_count,
            invitation_sent_users_count: response.data.data.invitation_sent_users_count
          }
        }));
      }
    })
}

export function revokeUserAccessApi(props, user) {
  this.setState({ pageLoader: true });
  globalDeleteService(`v2/revoke-user-access/${user.slug}/?application=mr_calculator`)
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.setState(prevState => ({
          ...prevState,
          users: {
            ...prevState.users,
            list: prevState.users.list.filter(item => item.slug !== user.slug),
            pagination: {
              ...prevState.users.pagination,
              total: prevState.users.pagination.total - 1
            }
          },
          removeUser: {
            ...prevState.removeUser,
            modal: false,
            user: null
          }
        }));
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}
export function getRolesApi(props, loaderType) {
  this.setState({ [loaderType]: true });
  globalGetService(`v2/get-sparta-groups/`, { application: 'mr_calculator' })
    .then(response => {
      this.setState({ [loaderType]: false });
      if (checkApiStatus(response)) {
        this.setState({ roleList: response.data.data })
      }
    })
}
export function exportUserListApi(props = {}, query = {}) {
  this.setState({ pageLoader: true });
  globalExportService(`v2/get-user-list/?application=4`, { ...query, download: query.extension })
    .then(response => {
      this.setState({ pageLoader: false, query: {}, type: '' });
      downloadFileType(response.data, (`CommVerge_Users`), query.extension);
      trackActivity('Item Exported', { page_title: 'User(s) List', extension: query.extension })
    })
}
export function getRoleUsersApi(props, role) {
  this.setState({ pageLoader: true });
  globalGetService(`v2/get-sparta-group-users/`, { group_id: role.id, application: 'mr_calculator' })
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.setState({ roleDetail: { modal: true, data: response.data.data } });
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}
export function onChangeUserRoleApi(props, editRole) {
  if (editRole.role) {
    this.setState({ pageLoader: true });
    globalPutService(`v2/update-user-sparta-group/${editRole.user.slug}/?application=mr_calculator`, { sparta_group_list: [editRole.role.id] })
      .then(response => {
        this.setState({ pageLoader: false });
        if (checkApiStatus(response)) {
          this.setState(prevState => ({
            ...prevState,
            editRole: { modal: false, role: null, user: null },
            users: {
              ...prevState.users,
              list: prevState.users.list.map(user => user.slug === editRole.user.slug ? {
                ...user, sparta_group: editRole.role.name
              } : user)
            }
          }))
          this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
  } else {
    this.props.enqueueSnackbar('Please select the Role', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
  }
}
