
import { globalGetService, globalExportService } from '../../utils/globalApiServices';
import { checkApiStatus, downloadFileType, removeEmptyKey } from '../../utils';

export function getMrAuditLogsApi(props = {},query ,loaderType ){
    this.setState({[loaderType]:true})
    globalGetService(`new-mr-calculator/mr_audit_list/`, removeEmptyKey(query))
    .then(response => {
        this.setState({[loaderType]:false})
        if(checkApiStatus(response)){
            delete query.sort;
            delete query.sort_by;
            delete query.page;
            delete query.per_page;
            // delete query.date
            this.setState({
                auditData:response.data.data,
                filter:query
            })
        }
    })
}
export function exportMrAuditLogApi(props={},query={}){
    this.setState({pageLoader:true})
    globalExportService(`new-mr-calculator/mr_audit_list/`,query)
    .then(response => {
        this.setState({pageLoader:false})
        downloadFileType(response.data, (`audit_log`),'xlsx' );
    })
}
