import React, { Fragment } from 'react';
import { TableRow, TableCell, Tooltip } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import BlockIcon from '@material-ui/icons/Block';
const AnalyzeMRCashflow = ({roleType}) => {
  return(
    <Fragment>
      <TableRow hover tabIndex={-1}>
        <TableCell><h4>CommVerge Cashflow Analyzer</h4></TableCell>
        <TableCell>
          <Tooltip title={['MR Calculator Manager', 'Lessor Administrators'].includes(roleType) ? 'Access': 'Not Applicable'} arrow>
            {!['MR Calculator Manager', 'Lessor Administrators'].includes(roleType) ?
              <CancelIcon style={{color:'#8B0000'}} />:<CheckCircleIcon style={{color:'#006400'}} />
            }
          </Tooltip>
        </TableCell>
        <TableCell>
          <Tooltip title={['MR Calculator Manager', 'Lessor Administrators'].includes(roleType) ? 'Access': 'Not Applicable'} arrow>
            {!['MR Calculator Manager', 'Lessor Administrators'].includes(roleType) ?
              <CancelIcon style={{color:'#8B0000'}} />:<CheckCircleIcon style={{color:'#006400'}} />
            }
          </Tooltip>
        </TableCell>
        <TableCell>
          <Tooltip title={['MR Calculator Manager', 'Lessor Administrators'].includes(roleType) ? 'Access': 'Not Applicable'} arrow>
            {!['MR Calculator Manager', 'Lessor Administrators'].includes(roleType) ?
              <CancelIcon style={{color:'#8B0000'}} />:<CheckCircleIcon style={{color:'#006400'}} />
            }
          </Tooltip>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}
export default AnalyzeMRCashflow;
