import React, { Fragment, useState } from 'react';
import { Grid, TextField, Tooltip, InputAdornment, IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { LabelValueCard, CountryStateSelector } from '../../../shared_elements';
import { regexConstants } from '../../../constants/regEx';
import EditIcon from '@material-ui/icons/Edit';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
const EngineBasicInfo = ({simulationType, financeRptInfo, simulateFinanceRptInfo, onFieldChange, handleSelect, setRegionModal, onAddressChange, address,financeError, updateErrorEngineField}) => {
  return(
    <Grid container spacing={2}>
      <Grid item md={2}>
        <TextField
          disabled={true}
          id="engine_type"
          label="Engine Type"
          fullWidth
          margin="none"
          value={financeRptInfo.engines.general_info.engine_type.name}
          InputLabelProps={{shrink: true}}
          variant="outlined"
        />
      </Grid>
      <Grid item md={2}>
        { window.google !== undefined && window.google !== null ?
          <PlacesAutocomplete
            value={address}
            onChange={onAddressChange}
            onSelect={(address) => {handleSelect(address); updateErrorEngineField('regionOfOperation')} }
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div style={{position:'relative'}}>
                <label style={{zIndex:4,color: simulationType === '1' || simulationType === '3' ? 'rgba(0, 0, 0,.35)':'rgba(0, 0, 0)', position:'absolute', fontSize:'11px', background:'#fff', padding:'0 4px', top:'-8px', left:'8px', fontWeight:'700'}}>Region of Operation </label>
                <input
                  readOnly={simulationType === '1' || simulationType === '3' ? true:false}
                  {...getInputProps({
                    placeholder: 'Search Airports...',
                    className: 'location-search-input',
                    style: {padding:'2px 8px', height:'40px', border:'1px solid rgba(0, 0, 0, 0.35)', borderRadius:'4px', background:'transparent', width:'90%', opacity: simulationType === '1' || simulationType === '3' ?'0.5':1}
                  })}
                />
              <div className="autocomplete-dropdown-container" style={{position:'absolute', left:'0px', top:'30px', zIndex:'999', width:'93%'}}>
                  {loading && <div>Loading...</div>}
                  {suggestions.map(suggestion => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>:
          <div className="label-value-card">
            <h6>
              {
                financeRptInfo.location && financeRptInfo.location.address ? financeRptInfo.location.address.length > 20 ? <Tooltip title={financeRptInfo.location.address}><span>{`${financeRptInfo.location.address.substr(0, 20)}...`}</span></Tooltip>: financeRptInfo.location.address:
                financeRptInfo.engines.general_info.region.name
              }
              <IconButton color="primary" onClick={() => setRegionModal(true)} component="span" size="small">
                <EditIcon color="primary" />
              </IconButton>
            </h6>
          </div>
        }
        { simulationType === '3' ?
          <Fragment>
            <div>
              { financeRptInfo.location && financeRptInfo.location.address ?
                <div>
                  { financeRptInfo.engines.general_info.region.name !== simulateFinanceRptInfo.engines.general_info.region.name ?
                    <div>
                      <Tooltip title={financeRptInfo.engines.general_info.region.name}>
                        <span style={{color:'#ff0000', fontSize:'12px'}}>
                          { financeRptInfo.engines.general_info.region.name.length > 20 ? financeRptInfo.engines.general_info.region.name.substr(0,20)+'...': financeRptInfo.engines.general_info.region.name}
                        </span>
                      </Tooltip>
                      <Tooltip title="Original Value" arrow><InfoIcon style={{fontSize:'13px', position:'relative', top:'2px', left:'2px'}} /></Tooltip>
                    </div>:null
                  }
                </div>:null
              }
            </div>
          </Fragment>:null
        }
        {financeError && financeError.regionOfOperation ?
          <h6 style={{color:'#f44336'}}>{financeError.regionOfOperation}</h6> : null
        }

      </Grid>
      <Grid item md={3}>
        <TextField
          required
          disabled={simulationType === '1' || simulationType === '3' ? true:false}
          id="hourly_utilization"
          label="Monthly Utilization in Hours"
          fullWidth
          margin="none"
          error={financeError && financeError.hourly_utilization ? true : false}
          helperText={financeError && financeError.hourly_utilization ? financeError.hourly_utilization : ''}
          value={simulationType === '3' ? simulateFinanceRptInfo.engines.general_info.hourly_utilization :financeRptInfo.engines.general_info.hourly_utilization}
          onFocus={() =>  {updateErrorEngineField('hourly_utilization'); updateErrorEngineField('utilization_ratio')}}
          InputLabelProps={{shrink: true}}
          onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'hourly_utilization', e.target.value, 'engines', 'general_info'):e.preventDefault()}}
          InputProps={{
            endAdornment: <InputAdornment position="end">FH</InputAdornment>
          }}
          variant="outlined"
        />
        { simulationType === '3' &&  financeRptInfo.engines.general_info.hourly_utilization !==  simulateFinanceRptInfo.engines.general_info.hourly_utilization ? 
          <p style={{color:'#ff0000', fontSize:'12px'}}>
            {financeRptInfo.engines.general_info.hourly_utilization}
            <Tooltip title="Original Value" arrow><InfoIcon style={{fontSize:'13px', position:'relative', top:'2px', left:'2px'}} /></Tooltip>
          </p>:null
        }
      </Grid>
      <Grid item md={3}>
        <TextField
          disabled={simulationType === '1' || simulationType === '3' ? true:false}
          required
          id="cycle_utilization"
          label="Monthly Utilization in Cycle"
          fullWidth
          margin="none"
          error={financeError && financeError.cycle_utilization ? true : false}
          helperText={financeError && financeError.cycle_utilization ? financeError.cycle_utilization : ''}
          value={simulationType === '3' ? simulateFinanceRptInfo.engines.general_info.cycle_utilization :financeRptInfo.engines.general_info.cycle_utilization}
          onFocus={() => {updateErrorEngineField('cycle_utilization'); updateErrorEngineField('utilization_ratio')}}
          InputLabelProps={{shrink: true}}
          onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'cycle_utilization', e.target.value, 'engines', 'general_info'): e.preventDefault()}}
          InputProps={{
            endAdornment: <InputAdornment position="end">FC</InputAdornment>
          }}
          variant="outlined"
        />
        { simulationType === '3' && financeRptInfo.engines.general_info.cycle_utilization !==  simulateFinanceRptInfo.engines.general_info.cycle_utilization ?
          <p style={{color:'#ff0000', fontSize:'12px'}}>
            {financeRptInfo.engines.general_info.cycle_utilization}
            <Tooltip title="Original Value" arrow><InfoIcon style={{fontSize:'13px', position:'relative', top:'2px', left:'2px'}} /></Tooltip>
          </p>:null
        }          
      </Grid>
      <Grid item md={2}>
        <TextField
          disabled
          id="utilization_ratio"
          label="Utilization Ratio"
          fullWidth
          margin="none"
          error={financeRptInfo.engines.general_info.utilization_ratio > financeRptInfo.engines.general_info.utilization_max_ratio || financeRptInfo.engines.general_info.utilization_ratio < financeRptInfo.engines.general_info.utilization_min_ratio ? true :false}
          helperText={financeRptInfo.engines.general_info.utilization_ratio > financeRptInfo.engines.general_info.utilization_max_ratio || financeRptInfo.engines.general_info.utilization_ratio < financeRptInfo.engines.general_info.utilization_min_ratio  ? `Utilization Ratio should be between ${financeRptInfo.engines.general_info.utilization_min_ratio}:1 and ${financeRptInfo.engines.general_info.utilization_max_ratio}:1` : '' }
          value={`${simulationType === '3' ? simulateFinanceRptInfo.engines.general_info.utilization_ratio :financeRptInfo.engines.general_info.utilization_ratio}:1`}
          InputLabelProps={{shrink: true}}
          variant="outlined"
        />
        { simulationType === '3' && financeRptInfo.engines.general_info.utilization_ratio !==  simulateFinanceRptInfo.engines.general_info.utilization_ratio ?
          <p style={{color:'#ff0000', fontSize:'12px'}}>
            {financeRptInfo.engines.general_info.utilization_ratio}:1
            <Tooltip title="Original Value" arrow><InfoIcon style={{fontSize:'13px', position:'relative', top:'2px', left:'2px'}} /></Tooltip>
          </p>:null
        }
      </Grid>
    </Grid>
  )
}
export default EngineBasicInfo;
