import React, { Fragment } from 'react';
import config from '../../config';
import { Link } from "@material-ui/core" ;
import { getLocalStorageInfo } from '../../utils';
import settingsIcon from '../../assets/images/setting.svg';
import homeIcon from '../../assets/images/home_icon.png';
import reviewDockIcon from '../../assets/images/review_dock.png';
export default function MainNavLink(){
    if(getLocalStorageInfo()) {
      return(
        <Fragment>
          <ul className="list-unstyled" style={{marginTop:'20px'}}>
            <li>
              <a href={config.domain.subDomian} target="_blank" rel="noreferrer">
                <img src={homeIcon} alt="Home"/>
                <p>Home</p>
              </a>
            </li>
          </ul>
          <ul className="list-unstyled" style={{paddingBottom:'80px'}}>
            <li className={window.location.href.includes('initiate') || window.location.href.includes('logs') || window.location.href.includes('intermediate') || window.location.href.includes('financial-plots')  ? 'active':'' } >
              <Link href="/logs">
                <img src={`${config.api.s3ImageUrl}mr_cal_icon.png`} alt="CommVerge" />
                <p>Logs</p>
              </Link>
            </li>
            <li className={window.location.href.includes('audit-log') ? 'active':''} >
              <Link  href="/audit-log">
                <img src={reviewDockIcon} alt="Review Dock" />
                <p>Audit Log</p>
              </Link>
            </li>
            { getLocalStorageInfo() && getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.id === 242 ?
        getLocalStorageInfo().user && getLocalStorageInfo().user.security_groups && getLocalStorageInfo().user.security_groups.filter(group => group.id === 29).length ?
        <li className={window.location.href.includes('settings') ? 'active':''}>
          <Link href={`/settings/users`}>
            <img src={settingsIcon} alt="users" />
            <p>Settings</p>
          </Link>
        </li>:null:<li className={window.location.href.includes('settings') ? 'active':''}>
            <Link href={`/settings/users`}>
              <img src={settingsIcon} alt="users" />
              <p>Settings</p>
            </Link>
          </li>
      }
          </ul>
          </Fragment>
      )
    }else {
      return null
    }

}
